import React from 'react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';

const ArrayCell = ({ value, isLink = false }) => {
  const array = value?.filter(Boolean);
  if (isEmpty(array)) {
    const handleClick = (e) => {
      if (!isLink) return;

      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <div onClick={handleClick} className="appealio-table__custom-cell">
        <span data-tip="None">None</span>
      </div>
    );
  }

  const firstArrayItem = array[0];
  const arrayLength = array.length;

  const dataToRender =
    arrayLength === 1
      ? firstArrayItem
      : `${firstArrayItem} +${arrayLength - 1}`;

  const dataTip = array.length === 1 ? array : array.join(', ');

  return (
    <div
      className={classnames('appealio-table__custom-cell', {
        'appealio-table__custom-cell--is-link': isLink,
      })}
    >
      <span data-for="array-tooltip" data-tip={dataTip}>
        {dataToRender}
      </span>
      <ReactTooltip id="array-tooltip" effect="solid" place="top" />
    </div>
  );
};

export default ArrayCell;
