import * as routes from 'constants/routes';

export const SETTINGS_MAIN_NAV_ITEMS = [
  // {
  //   to: routes.MANAGER_SETTINGS_ACCOUNT,
  //   title: "Account Details",
  // },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION,
    title: 'Organization',
  },
  // {
  //   to: routes.MANAGER_SETTINGS_PAYER_PORTALS,
  //   title: "Payer Portals",
  // }
];

export const SETTINGS_NAVIGATION_INTRO = {
  // 'account': {
  //   title: 'Account Details',
  //   description: 'Manage your account details',
  //   info: 'Account info',
  // },
  organization: {
    title: 'Organization',
    description:
      'This panel allows you to reconfigure the structure of your organization.',
    info: 'This panel allows you to reconfigure the structure of your organization.',
  },
  // 'payerPortals': {
  //   title: 'Payer Portals',
  //   description: 'This panel allows you to configure the Payer Portals for your organization.',
  //   info: 'Payer Portals info',
  // },
};

export const SETTINGS_ORGANIZATION_NAV_ITEMS = [
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_USERS,
    title: 'Users',
  },

  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_PRACTICES,
    title: 'Practices',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_PRACTICE_GROUP,
    title: 'Practice Groups',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_TEAMS,
    title: 'Teams',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_CLINICS,
    title: 'Clinics',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_BILLING_PROVIDERS,
    title: 'Billing Providers',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_RENDERING_PROVIDERS,
    title: 'Rendering Providers',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_PAYERS,
    title: 'Payers',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_PAYER_CONTACTS,
    title: 'Payer Contacts',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_TEMPLATES,
    title: 'Templates & Forms',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_DENIAL_ACTIONS,
    title: 'Actions',
  },
  {
    to: routes.MANAGER_SETTINGS_ORGANIZATION_FEE_SCHEDULES,
    title: 'Fee Schedules',
  },
];

export const EXPRESS_SETTINGS_NAV_ITEMS = [
  {
    to: routes.EXPRESS_SETTINGS_USERS,
    title: 'Users',
  },
  {
    to: routes.EXPRESS_SETTINGS_RETURN_CONTACTS,
    title: 'Return Contacts',
  },
  {
    to: routes.EXPRESS_SETTINGS_DESTINATION_CONTACTS,
    title: 'Destination Contacts',
  },
];
