import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { TableVirtuoso } from 'react-virtuoso';
import { GrCircleInformation } from 'react-icons/gr';
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import './customDropdown.css';

import {
  ALL_OPTION_LABEL,
  ALL_AGENTS_OPTION_VALUE,
} from 'constants/appConstants';

import { sortDropdownList } from './utils';

import SearchBox from './SearchBox';
import OutsideClickWrapper from 'components/common/outsideClickWrapper/OutsideClickWrapper';
const ITEM_HEIGHT = 36;
const ALL_OPTION = {
  label: ALL_OPTION_LABEL,
  value: ALL_AGENTS_OPTION_VALUE,
  isAppealioAllOption: true,
};

const SEARCH_BOX_HEIGHT = 50;

export const appendAllOption = (
  options,
  allOptionValue = ALL_AGENTS_OPTION_VALUE
) => [
  {
    ...ALL_OPTION,
    value: allOptionValue,
  },
  ...options,
];

const flattenOptions = (options, skipGroupLabel = false) => {
  let flattened = [];

  options.forEach((option) => {
    if (option.options && Array.isArray(option.options)) {
      if (!skipGroupLabel) {
        flattened.push({ label: option.label, isGroupLabel: true });
      }
      flattened = flattened.concat(option.options);
    } else {
      flattened.push(option);
    }
  });

  return flattened;
};

const CustomDropdown = (props) => {
  const {
    input = {},
    label,
    isMulti,
    options,
    className = '',
    onChange = () => {},
    isDisabled = false,
    dataTipMessage = '',
    placeholder = 'Select...',
    components,
    maxListItems = 5,
    sortFunc,
    labelForAllSelectedOption = 'All',
    showAllSelectedLabel = true,
    disableRemovalOfOptions = false,
    meta: { touched = false, error = '' } = {},
    hideClearAllButton = false,
    dataToolTipPlace = 'top',
    hideLabel = false,
    disableOptionSelection = false,
  } = props;

  const [showList, setShowList] = useState(false);
  const [renderOptions, setRenderOptions] = useState(options);
  const [selectedValue, setSelectedValue] = useState([]);
  const wrapperRef = useRef(null);
  const [computeDropdownWrapperHeight, setComputeDropdownWrapperHeight] =
    useState({
      isSmallHeight: false,
      isSmallHeightComputed: false,
    });
  useEffect(() => {
    const initialValues = input.value?.toJS ? input.value?.toJS() : input.value;
    setSelectedValue(initialValues || []);
  }, [input.value]);
  const BOTTOM_HEIGHT_OFFSET =
    ITEM_HEIGHT * (maxListItems + 1) + SEARCH_BOX_HEIGHT;
  useEffect(() => {
    if (!showList) return;
    if (wrapperRef.current) {
      const bottomHeight =
        window.innerHeight - wrapperRef.current.getBoundingClientRect().bottom;
      const windowZoomRatio = (window.outerWidth - 10) / window.innerWidth;
      if (bottomHeight) {
        if (bottomHeight < BOTTOM_HEIGHT_OFFSET / windowZoomRatio) {
          setComputeDropdownWrapperHeight((prevState) => ({
            ...prevState,
            isSmallHeight: true,
          }));
        } else {
          setComputeDropdownWrapperHeight((prevState) => ({
            ...prevState,
            isSmallHeight: false,
          }));
        }
      }
    }
    setComputeDropdownWrapperHeight((prevState) => ({
      ...prevState,
      isSmallHeightComputed: true,
    }));
  }, [showList, BOTTOM_HEIGHT_OFFSET]);

  const flattenAndSetRenderOptions = (options) =>
    setRenderOptions(flattenOptions(options));
  const sortSelectedValue = useCallback(
    () =>
      flattenAndSetRenderOptions(
        sortDropdownList(selectedValue, options, sortFunc)
      ),
    [selectedValue, options, sortFunc]
  );

  const [searchValue, setSearchValue] = useState('');
  const clearSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchValue('');
    flattenAndSetRenderOptions(options);
  };
  const filterOptionsRecursively = (optionsList, searchTerm) => {
    return optionsList.reduce((acc, option) => {
      if (option?.options && Array.isArray(option.options)) {
        const filteredNestedOptions = filterOptionsRecursively(
          option.options,
          searchTerm
        );

        if (
          filteredNestedOptions.length > 0 ||
          option.label.toLowerCase().includes(searchTerm)
        ) {
          acc.push({
            ...option,
            options: filteredNestedOptions,
          });
        }
      } else if (option.label.toLowerCase().includes(searchTerm)) {
        acc.push(option);
      }

      return acc;
    }, []);
  };

  const handleSearchChange = (e) => {
    const searchInput = e.target.value.toLowerCase();

    let filteredOptions;

    if (!searchInput) {
      filteredOptions = [...options];
    } else {
      filteredOptions = filterOptionsRecursively([...options], searchInput);
    }

    setSearchValue(e.target.value);
    flattenAndSetRenderOptions(filteredOptions);
  };

  useEffect(() => {
    if (showList) return;
    sortSelectedValue();
  }, [showList, sortSelectedValue]);

  const removeOption = (option) => {
    return selectedValue.filter(
      (o) => !o.isAppealioAllOption && o.value !== option.value
    );
  };

  const findSelectedValue = (option) =>
    selectedValue.findIndex((o) => o.value === option.value) >= 0;

  const handleChange = (value) => {
    if (input?.onChange) {
      input.onChange(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  const onItemClick = (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    if (disableOptionSelection) {
      return;
    }

    if (option.isGroupLabel) {
      return;
    }

    let newValue;
    if (isMulti) {
      if (findSelectedValue(option)) {
        if (disableRemovalOfOptions) {
          return; // Prevent removal of options
        }

        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = [option];
    }

    if (option.isAppealioAllOption) {
      newValue =
        selectedValue.length === flattenOptions(options).length
          ? []
          : flattenOptions(options);
    }
    setSelectedValue(newValue);
    handleChange(newValue);
    if (!isMulti) {
      setShowList(false);
    }
  };

  const handleDropdownClick = () => {
    setTimeout(() => {
      setSearchValue('');
      setShowList(!showList);
    }, 50);
  };

  const isAllOptionsSelected = () => {
    return selectedValue.length === flattenOptions(options, true).length;
  };
  const displaySelectedValue = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeholder;
    }

    if (showAllSelectedLabel && isAllOptionsSelected(selectedValue)) {
      return labelForAllSelectedOption;
    }

    return selectedValue[0].label;
  };

  const handleClearSelection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedValue([]);
    setShowList(false);
    handleChange(isMulti ? [] : null);
  };

  const DisplaySelectedValue = () => {
    if (props?.displayValue) {
      return props.displayValue;
    }

    const showCounterBadge =
      isMulti && selectedValue.length > 1 && !isAllOptionsSelected();
    const displayValue = displaySelectedValue();
    const dataTip = displayValue === placeholder ? '' : displayValue;
    if (showCounterBadge) {
      const totalSelectedOption = selectedValue.length;
      const displayCount = `+${totalSelectedOption - 1}`;
      const isAllValuesSelected = selectedValue.find(
        (value) => value === ALL_OPTION.value
      );
      return (
        <div className="d-flex align-items-center">
          <div data-tip={dataTip} className="dd-value">
            {displayValue}
          </div>
          {!isAllValuesSelected && (
            <div className="counter-badge ml-8">{displayCount}</div>
          )}
          <ReactTooltip effect="solid" place="top" />
        </div>
      );
    }
    return (
      <div>
        <span data-tip={dataTip} className="dd-value">
          {displayValue}
        </span>
        <ReactTooltip effect="solid" place="top" />
      </div>
    );
  };

  const ListItem = ({ index }) => {
    const item = renderOptions[index];
    // TODO: Fixup new design
    if (item.isGroupLabel) {
      return <div className="dd-list__group-label">{item.label}</div>;
    }

    return (
      <>
        <div
          className={classnames('dd-list__item', {
            'dd-list__item-border-bottom': item?.isAppealioAllOption,
          })}
          key={index}
          onClick={(e) => onItemClick(e, item)}
        >
          {isMulti && (
            <input
              type="checkbox"
              checked={findSelectedValue(item)}
              readOnly
              disabled={disableRemovalOfOptions && findSelectedValue(item)}
              className="mr-12"
            />
          )}{' '}
          {components?.Option ? (
            <components.Option item={item} />
          ) : (
            <div
              className={classnames({
                'opacity-50': item.isActive === false,
              })}
            >
              {item.label}
            </div>
          )}
        </div>
      </>
    );
  };

  const ListItemNotFound = () => {
    return (
      <>
        <div className={classnames('dd-list__item dd-list__item--gray')}>
          <div>No Options</div>
        </div>
      </>
    );
  };

  const showClearButton = !hideClearAllButton && selectedValue.length > 0;

  const height =
    renderOptions.length < maxListItems
      ? renderOptions.length * ITEM_HEIGHT
      : maxListItems * ITEM_HEIGHT;

  const ErrorMessage = ({ touched, error }) => {
    if (!touched && !error) return null;
    if (!touched) return <span className="ap-input-error">&nbsp;</span>;
    return <span className="ap-input-error">{error}</span>;
  };

  return (
    <div ref={wrapperRef}>
      <OutsideClickWrapper
        handleOutsideClick={() => showList && setShowList(false)}
      >
        <div
          className={classnames('dd-wrapper', className, {
            'dd-wrapper--disabled': isDisabled,
          })}
        >
          <div className="d-flex align-items-center">
            {!hideLabel && <p className="dd-label">{label}</p>}
            {dataTipMessage && (
              <>
                <GrCircleInformation
                  size="14"
                  className="ml-8 mb-6"
                  color="#5E6871"
                  data-tip={dataTipMessage}
                  data-for={`info-icon-tooltip--${dataTipMessage}`}
                />
                <ReactTooltip
                  effect="solid"
                  place={dataToolTipPlace}
                  id={`info-icon-tooltip--${dataTipMessage}`}
                />
              </>
            )}
          </div>
          <div
            onClick={handleDropdownClick}
            className={classnames('dd-value-container', {
              'dd-value-container--active': showList,
            })}
          >
            <div
              className={classnames('dd-selected-value', {
                'dd-selected-value--gray': !selectedValue.length,
              })}
            >
              <DisplaySelectedValue />
            </div>
            <div className="d-flex align-items-center">
              {showClearButton && (
                <FiX
                  size="18"
                  className="dd-clear-label"
                  data-tip="Clear Selection"
                  onClick={handleClearSelection}
                />
              )}
              {!showList ? (
                <FiChevronDown size="18" className="dd-toggle-icon " />
              ) : (
                <FiChevronUp size="18" className="dd-toggle-icon " />
              )}
            </div>
          </div>
          {showList && computeDropdownWrapperHeight.isSmallHeightComputed && (
            <div
              className={classnames('dd-list__wrapper', {
                'dd-wrapper--top-design':
                  computeDropdownWrapperHeight.isSmallHeight,
              })}
              style={{
                minHeight: height + SEARCH_BOX_HEIGHT,
                ...(!renderOptions.length && { height: 'auto' }),
              }}
            >
              <SearchBox
                placeholder="Search"
                searchValue={searchValue}
                onChange={handleSearchChange}
                clearSearch={clearSearch}
              />
              {renderOptions.length ? (
                <TableVirtuoso
                  style={{ height }}
                  totalCount={renderOptions.length}
                  itemContent={(index) => {
                    return (
                      <>
                        <td
                          style={{
                            width: '100%',
                          }}
                        >
                          <ListItem index={index} />
                        </td>
                      </>
                    );
                  }}
                />
              ) : (
                <ListItemNotFound />
              )}
            </div>
          )}
          <ErrorMessage touched={touched} error={error} />
        </div>
      </OutsideClickWrapper>
    </div>
  );
};

CustomDropdown.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default CustomDropdown;
