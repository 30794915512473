import { sortBy } from 'lodash';

import { interpolate } from 'helpers/string';
import { camelizeKeys, snakeCaseKeys } from '../helpers/object';

import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  createRequest,
  createFormDataRequest,
  BASE_API_URL,
  INTEGRATION_API_BASE_URL,
} from './Config';

import * as endpoints from '../constants/endpoints';
import { DENIAL_ACTION_CONFIG_TYPE } from 'constants/appConstants';

export const fetchPractices = async (queryParam) => {
  const url = endpoints.PRACTICES;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const setHomePage = async (homePage) => {
  const data = {
    home_page: homePage,
  };

  const request = createRequest(endpoints.SET_CLIENT_HOME_PAGE, data, {
    baseUrl: BASE_API_URL,
    method: 'PUT',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPracticeGroups = async (queryParam) => {
  const url = endpoints.PRACTICE_GROUPS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPracticeGroup = async (id) => {
  const url = `${endpoints.PRACTICE_GROUPS}${id}`;
  const request = createGetRequest(url, {}, BASE_API_URL);

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createPracticeGroup = async (data) => {
  const url = `${endpoints.PRACTICE_GROUPS}`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updatePracticeGroup = async (id, data) => {
  const url = `${endpoints.PRACTICE_GROUPS}${id}`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return await fetch(request).then(handleErrors);
};

export const fetchPractice = async (practiceId, queryParam = {}) => {
  const url = `${endpoints.PRACTICES}${practiceId}`;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPracticeClinicCount = (queryParam, signal) => {
  const url = endpoints.SEARCH_CLINICS_FOR_PRACTICE;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createPractice = async (data) => {
  const url = `${endpoints.PRACTICES}`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updatePractice = async (data) => {
  const { id } = data;
  const url = `${endpoints.PRACTICES}${id}/`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const deactivatePractice = async (practiceId) => {
  const url = `${endpoints.PRACTICES}${practiceId}`;

  const request = createRequest(
    url,
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'DELETE',
    }
  );

  return await fetch(request).then(handleErrors);
};

export const requestReactivate = async (modelId, modelType) => {
  const url = `${endpoints.REQUEST_REACTIVATE}`;

  const request = createRequest(
    url,
    {
      model_id: modelId,
      model_type: modelType,
    },
    {
      baseUrl: BASE_API_URL,
      method: 'POST',
    }
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchClinics = (queryParam, signal) => {
  const url = endpoints.CLINICS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createClinic = async (data) => {
  const url = `${endpoints.CLINICS}/`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updateClinic = async (data) => {
  const { id } = data;
  const url = `${endpoints.CLINICS}${id}/`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchBillingProviders = (queryParam, signal) => {
  const url = endpoints.BILLING_PROVIDERS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPracticeBillingProviderCount = (queryParam, signal) => {
  const url = endpoints.SEARCH_BILLING_PROVIDERS_FOR_PRACTICE;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createBillingProvider = async (data) => {
  const url = `${endpoints.BILLING_PROVIDERS}/`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updateBillingProvider = async (data) => {
  const { id } = data;
  const url = `${endpoints.BILLING_PROVIDERS}${id}/`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchRenderingProviders = (queryParam) => {
  const url = endpoints.RENDERING_PROVIDERS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPracticeRenderingProviderCount = (queryParam, signal) => {
  const url = endpoints.SEARCH_RENDERING_PROVIDERS_FOR_PRACTICE;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createRenderingProvider = async (data) => {
  const url = `${endpoints.RENDERING_PROVIDERS}/`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updateRenderingProvider = async (data) => {
  const { id } = data;
  const url = `${endpoints.RENDERING_PROVIDERS}${id}/`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPayers = async (queryParam) => {
  const url = endpoints.PAYERS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createPayer = async (data) => {
  const url = `${endpoints.PAYERS}/`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const updatePayer = async (data) => {
  const { id } = data;
  const url = `${endpoints.PAYERS}${id}/`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const clonePayer = async (data) => {
  const url = `${endpoints.CLONE_PAYERS}/`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPayerContacts = (queryParam, signal) => {
  const url = endpoints.PAYER_CONTACTS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPayerContactCounts = (queryParam, signal) => {
  const url = endpoints.SEARCH_CONTACTS_FOR_PAYER;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createPayerContact = async (data) => {
  const url = `${endpoints.PAYER_CONTACTS}/`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Update Payer Contact.
 *
 * @param {Object} data
 * @returns {Promise<Object>}
 */
export const updatePayerContact = async (data) => {
  const { id } = data;
  const url = `${endpoints.PAYER_CONTACTS}${id}`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createUser = async (data) => {
  const url = `${endpoints.MANAGE_USERS}`;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const deactivateClinic = async (clinicId) => {
  const url = `${endpoints.CLINICS}${clinicId}`;

  const request = createRequest(
    url,
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'DELETE',
    }
  );
  return await fetch(request).then(handleErrors);
};

/**
 * Deactivate Payer Contact.
 *
 * @param {payerContactId} data
 * @returns {Promise<Object>}
 */
export const deactivatePayerContact = async (payerContactId) => {
  const url = `${endpoints.PAYER_CONTACTS}${payerContactId}`;
  const request = createRequest(
    url,
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'DELETE',
    }
  );
  return fetch(request).then(handleErrors);
};

/**
 * Activate Payer Contact.
 *
 * @param {payerContactId} data
 * @returns {Promise<Object>}
 */
export const activatePayerContact = async (data, payerContactId) => {
  const url = `${endpoints.PAYER_CONTACTS}${payerContactId}`;
  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PUT',
  });
  return fetch(request).then(handleErrors);
};

/**
 * fetches the contacts of the AE user
 * @param {Object} queryParam
 * @returns {Promise<Object>}
 */
export const fetchContacts = (queryParam) => {
  const url = `${endpoints.CONTACTS}/`;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * creates the contact for the AE user
 * @param {object} data
 * @returns {Promise<Object>}
 */
export const createContact = (data) => {
  const url = `${endpoints.CONTACTS}/`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Update AE Contact.
 *
 * @param {Object} data
 * @returns {Promise<Object>}
 */
export const updateContact = (data) => {
  const { id } = data;
  const url = `${endpoints.CONTACTS}${id}`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'PATCH',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Deactivate AE Contact.
 *
 *  @param {contactId} id
 * @returns {Promise<Object>}
 */
export const deactivateContact = (contactId) => {
  const url = `${endpoints.CONTACTS}${contactId}`;
  const request = createRequest(
    url,
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'DELETE',
    }
  );
  return fetch(request).then(handleErrors);
};

/**
 * Activate AE Contact.
 *
 * @param {contactId} id
 * @param {data} data
 * @returns {Promise<Object>}
 */
export const activateContact = (data, contactId) => {
  const url = `${endpoints.CONTACTS}${contactId}`;
  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PUT',
  });
  return fetch(request).then(handleErrors);
};

export const parseContactDataToDropdown = (contacts) => {
  return sortBy(
    contacts
      .filter((contact) => contact.address1)
      .map((contact) => ({
        label: `${contact.name} ${!contact.isActive ? '(Inactive)' : ''}`,
        value: contact.id,
        isActive: contact.isActive,
        address: {
          name: contact.name,
          address1: contact.address1,
          address2: contact.address2,
          city: contact.city,
          state: contact.state,
          zipcode: contact.zipcode,
        },
      })),
    (contact) => !contact.isActive
  );
};

export const updatePayerDeadline = (data) => {
  const { id } = data;
  const url = `${endpoints.PAYERS}${id}/update-appeal-deadline`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'PUT',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchPayerAppealTemplateCount = (queryParam, signal) => {
  const url = endpoints.SEARCH_APPEAL_TEMPLATES_FOR_PAYER;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches payer identifiers based on query parameters.
 *
 * @param {Object} queryParam - Query parameters to filter payer identifiers.
 * @returns {Promise} Promise that resolves to the fetched payer identifiers.
 */
export const fetchPayerIdentifiers = (queryParam) => {
  const url = endpoints.PAYER_IDENTIFIERS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches Denial Actions based on query parameters.
 * @param {Object} queryParam
 * @returns {Promise<Object>}
 */
export const fetchDenialActions = (queryParam) => {
  const url = endpoints.DENIAL_ACTIONS;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    INTEGRATION_API_BASE_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Update/Create Denial Action Config.
 * @param {Object} data
 * @returns {Promise<Object>}
 */
export const updateDenialActionConfig = (data) => {
  const payload = {
    configType: DENIAL_ACTION_CONFIG_TYPE.AUTO_REMINDER,
    configData: data,
  };

  const request = createRequest(
    endpoints.UPDATE_DENIAL_ACTION_CONFIG,
    snakeCaseKeys(payload),
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'POST',
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchDvVerifiedContacts = (queryParam) => {
  const url = endpoints.CONTACTS_DV_VERIFIED;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetch Partners Information.
 *
 * @return {Promise<Object>} The partners information.
 */
export const fetchPartnersInfo = () => {
  const url = endpoints.PARTNERS;
  const request = createGetRequest(url);

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Creates Payer Identifiers
 * @param {object} data
 * @returns {Promise<Object>}
 */
export const createPayerIdentifier = (data) => {
  const url = endpoints.PAYER_IDENTIFIERS;
  const { partner, identificationNumber } = data;
  const requestData = {
    partner_id: partner?.value,
    identification_number: identificationNumber,
  };
  const request = createRequest(url, requestData, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Creates a new team.
 *
 * @param {object} data - The data for the new team.
 * @returns {Promise<Object>} The created team.
 */
export const createTeam = async (data) => {
  const url = endpoints.TEAMS;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches a team by its ID.
 *
 * @param {string} id - The ID of the team to fetch.
 * @returns {Promise<Object>} The fetched team.
 */
export const fetchTeam = async (id) => {
  const url = interpolate(endpoints.INDIVIDUAL_TEAM, {
    id,
  });
  const request = createGetRequest(url, {}, BASE_API_URL);

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches a list of teams based on the provided query parameters.
 *
 * @param {object} queryParam - The query parameters to filter the teams.
 * @returns {Promise<Object>} The fetched teams.
 */
export const fetchTeams = async (queryParam = {}) => {
  const url = endpoints.TEAMS;
  const request = createGetRequest(
    url,
    {
      ...snakeCaseKeys(queryParam),
    },
    BASE_API_URL
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Updates a team with the provided data.
 *
 * @param {string} id - The ID of the team to update.
 * @param {object} data - The data to update the team with.
 * @returns {Promise<void>} A promise that resolves when the team has been updated.
 */
export const updateTeam = async (id, data) => {
  const url = interpolate(endpoints.INDIVIDUAL_TEAM, {
    id,
  });
  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PUT',
  });

  return await fetch(request).then(handleErrors);
};

/**
 * Deletes a team with the provided ID.
 *
 * @param {string} id - The ID of the team to delete.
 * @returns {Promise<void>} A promise that resolves when the team has been deleted.
 */
export const deleteTeam = (id) => {
  const url = interpolate(endpoints.INDIVIDUAL_TEAM, {
    id,
  });

  const request = createRequest(
    url,
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'DELETE',
    }
  );

  return fetch(request).then(handleErrors);
};
/**
 * Requests a PM note template.
 *
 * @param {object} formData - The data to include in the request.
 * @returns {Promise<object>} A promise that resolves with the response data from the API.
 */
export const RequestPmTemplate = async (formData) => {
  const url = endpoints.REQUEST_PM_NOTE_TEMPLATE;

  const request = createFormDataRequest(
    url,
    formData,
    'POST',
    INTEGRATION_API_BASE_URL
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches payer with cpt count.
 *
 * @param {object} queryParam - The query parameters to filter the payers.
 * @param {AbortSignal} signal - Optional signal to abort the request if needed.
 * @returns {Promise<object>} A promise that resolves to the fetched payers with cpt count.
 */
export const fetchPayersWithCptCount = (queryParam, signal) => {
  const url = endpoints.FEE_SCHEDULE_STATS;
  const request = createGetRequest(
    url,
    {
      ...snakeCaseKeys(queryParam),
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches fee schedules based on query parameters.
 *
 * @param {Object} queryParam - The query parameters to filter the fee schedules.
 * @param {AbortSignal} signal - Optional signal to abort the request if needed.
 * @returns {Promise<Object>} A promise that resolves to the fetched fee schedules data.
 */
export const fetchFeeSchedules = (queryParam, signal) => {
  const url = endpoints.FEE_SCHEDULES;
  const request = createGetRequest(
    url,
    {
      ...snakeCaseKeys(queryParam),
    },
    BASE_API_URL
  );

  return fetch(request, { signal })
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Adds a new fee schedule.
 *
 * @param {Object} data - The data for the new fee schedule.
 * @returns {Promise<Object>} A promise that resolves to the added fee schedule.
 */
export const addFeeSchedule = (data) => {
  const url = endpoints.FEE_SCHEDULES;

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Updates a fee schedule.
 *
 * @param {string} id - The ID of the fee schedule to update.
 * @param {Object} data - The data to update the fee schedule with.
 * @returns {Promise<Object>} A promise that resolves to the updated fee schedule.
 */
export const updateFeeSchedule = async (id, data) => {
  const url = interpolate(endpoints.FEE_SCHEDULE_DETAILS, {
    id,
  });

  const request = createRequest(url, snakeCaseKeys(data), {
    baseUrl: BASE_API_URL,
    method: 'PUT',
  });

  return await fetch(request).then(handleErrors);
};

/**
 * Deletes a fee schedule with the provided ID.
 *
 * @param {string} id - The ID of the fee schedule to delete.
 * @returns {Promise<void>} A promise that resolves when the fee schedule has been deleted.
 */
export const deleteFeeSchedule = (id) => {
  const url = interpolate(endpoints.FEE_SCHEDULE_DETAILS, {
    id,
  });

  const request = createRequest(
    url,
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'DELETE',
    }
  );

  return fetch(request).then(handleErrors);
};
