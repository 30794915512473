import { sortBy, uniq, without } from 'lodash';

import {
  extractReactSelectOptions,
  sortDropdownOptionsByLabel,
} from 'helpers/utils';

import AppealsAPI from './AppealsAPI';
import DenialsAPI from 'API/DenialsAPI';
import CreateAppealAPI from './CreateAppealAPI';
import { fetchImportRulesOptions } from 'API/ImportRules';
import { fetchUsersAccessControl } from 'API/UsersAccessControls';
import {
  fetchPracticeGroups,
  fetchPractices,
  fetchTeams,
} from 'API/AccountSettingsAPI';

import { UNGROUPED_PRACTICE_NAME } from 'constants/appConstants';

/**
 * Fetches providers with additional data for React Select options.
 * @returns {Promise<Array>} An array of provider options for React Select.
 */
export const fetchProviders = async () => {
  const { providers } = await DenialsAPI.fetchProviders();
  return [
    { label: 'All', value: '' },
    ...sortBy(providers, ({ is_active: isActive }) => !isActive).map(
      ({
        id,
        name,
        practice_identifiers: practiceIdentifiers,
        is_active: isActive,
      }) => ({
        label: !isActive ? `${name} (Inactive)` : name,
        value: id,
        practiceIdentifiers,
        isActive,
      })
    ),
  ];
};

/**
 * Extracts options with 'value' and 'label' properties from an object.
 * @param {Object} option - The option object containing 'id' and 'name' properties.
 * @returns {Object} The extracted option with 'value' and 'label' properties.
 */
const extractOptions = ({ id, name }) => ({
  value: id,
  label: name,
});

/**
 * Fetches denial payers.
 * @returns {Promise<Array>} An array of denial payer options for React Select.
 */
export const fetchDenialPayers = async () => {
  const { payers } = await DenialsAPI.fetchPayers();
  return [{ label: 'All', value: '' }, ...payers.map(extractOptions)];
};

/**
 * Fetches denial reasons.
 * @returns {Promise<Array>} An array of denial reason options for React Select.
 */
export const fetchDenialReasons = async () => {
  const { reason_codes: reasonCodes } = await DenialsAPI.fetchReasonCodes();
  return extractReactSelectOptions(uniq(reasonCodes));
};

/**
 * Fetches denial remark codes.
 * @returns {Promise<Array>} An array of denial remark code options for React Select.
 */
export const fetchDenialRemarkCodes = async () => {
  const { remark_codes: remarkCodes } = await DenialsAPI.fetchRemarkCodes();
  return extractReactSelectOptions(uniq(remarkCodes));
};

/**
 * Fetches denial payer options.
 * @returns {Promise<Array>} An array of denial payer options for React Select.
 */
export const fetchPayerOptions = async () => {
  const payers = await AppealsAPI.getPayersOptions();

  return [
    { label: 'All', value: '' },
    ...payers.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];
};

/**
 * Fetches Appeals Providers options.
 * @returns {Promise<Array>} An array of denial payer options for React Select.
 */
export const fetchProviderOptions = async () => {
  const providers = await AppealsAPI.getDropdownOptions('providers');

  const parsedProviders = CreateAppealAPI.parseProviders(providers, true);
  return [
    {
      label: 'All',
      value: '',
    },
    ...parsedProviders.map(({ key: value, value: label }) => ({
      value,
      label,
    })),
  ];
};

/**
 * Fetches denial CPT codes.
 * @returns {Promise<Array>} An array of denial CPT code options for React Select.
 */
export const fetchDenialCptCodes = async (removeAllOption = false) => {
  const { cpt_codes: cptCodes } = await DenialsAPI.fetchCptCodes();
  return extractReactSelectOptions(
    uniq(without(cptCodes, null)),
    removeAllOption
  );
};

/**
 * Fetches denial procedure modifiers.
 * @returns {Promise<Array>} An array of denial procedure modifier options for React Select.
 */
export const fetchDenialProcedureModifiers = async () => {
  const { modifier_codes: procedureModifiers } =
    await DenialsAPI.fetchProcedureModifier();
  return extractReactSelectOptions(uniq(without(procedureModifiers, null)));
};

/**
 * Fetches denial rule options.
 * @returns {Promise<Array>} An array of denial rule options for React Select.
 */
export const fetchDenialRuleOptions = async () => {
  const ruleResponse = await fetchImportRulesOptions();
  const ruleOptions = ruleResponse.map(({ name }) => ({
    label: name,
    value: name,
  }));
  return [{ label: 'All', value: '' }, ...ruleOptions];
};

/**
 * Fetches agents with additional data for React Select options.
 * @returns {Promise<Array>} An array of agent options for React Select.
 */
export const fetchAgents = async () => {
  const agentsResponse = await fetchUsersAccessControl({ nopage: 1 });
  return [
    { label: 'All', value: '' },
    ...sortBy(agentsResponse, (o) => o.name).map((agent) => ({
      value: agent.username,
      label: agent.name,
      ...agent,
    })),
  ];
};

/**
 * Fetches available action logs and converts them for use in React Select.
 * @returns {Promise<Array>} An array of action log options for React Select.
 */
export const fetchActionLogsOptions = async () => {
  const { actionList } = await DenialsAPI.fetchActionLogs();

  const extractOptions = ({ id, description }) => ({
    value: id,
    label: description,
  });

  const appendOtherOption = (options) => [
    { value: '', label: 'All' },
    ...options,
    { value: 'Other', label: 'Other' },
  ];

  const options = appendOtherOption(actionList.map(extractOptions));

  return options;
};

/**
 * Fetches practice options and prepares them for use in React Select.
 * @returns {Promise<Array>} An array of practice options for React Select.
 */
export const fetchPracticeOptions = async () => {
  const data = await fetchPractices({
    all: 1,
    few: 1,
  });

  const mappedPractices = sortDropdownOptionsByLabel(
    data.map(({ practiceIdentifier, name, isActive, inGroups, id }) => ({
      id,
      value: practiceIdentifier,
      label: name,
      isActive,
      inGroups,
      isUngrouped:
        inGroups.length === 0 ||
        (inGroups.length === 1 && inGroups[0] === 'UnGrouped'),
    }))
  );
  return [{ label: 'All', value: '' }, ...mappedPractices];
};

/**
 * Fetches practice group options and prepares them for React Select.
 * @returns {Promise<Array>} An array of practice group options for React Select.
 */
export const fetchPracticeGroupOptions = async () => {
  const data = await fetchPracticeGroups({
    all: 1,
    few: 1,
  });
  const mappedPracticeGroups = sortDropdownOptionsByLabel(
    data.map(({ id, name }) => ({
      value: id,
      label: name === UNGROUPED_PRACTICE_NAME ? 'Ungrouped' : name,
    }))
  );
  return [{ label: 'All', value: '' }, ...mappedPracticeGroups];
};

/**
 * Fetches teams with additional data for React Select options.
 * @returns {Promise<Array>} An array of team options for React Select.
 */
export const fetchTeamOptions = async (param) => {
  const query = {
    ...param,
    excludeEmptyTeams: 1,
  };

  const { data: teamResponse } = await fetchTeams(query);
  return [
    { label: 'All', value: '' },
    ...sortBy(teamResponse, (o) => o.name).map((team) => ({
      value: team.id,
      label: team.name,
      ...team,
    })),
  ];
};
