import moment from 'moment';
import 'react-dates/initialize';
import React, { useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { ICON_AFTER_POSITION } from 'react-dates/constants';

const ReduxSingleDatePicker = ({
  input,
  meta: { touched, error },
  customInputIcon = <FiCalendar size="20px" />,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <div>
      <SingleDatePicker
        date={input.value ? moment(input.value) : null}
        onDateChange={(date) => input.onChange(date)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={1}
        customInputIcon={customInputIcon}
        inputIconPosition={ICON_AFTER_POSITION}
        hideKeyboardShortcutsPanel={true}
        {...props}
      />
      {touched && error && <span className="error">{error}</span>}
    </div>
  );
};

export default ReduxSingleDatePicker;
