import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import AppealsAPI from 'API/AppealsAPI';
import DenialsAPI from 'API/DenialsAPI';
import { fetchDeliveryLogs } from 'API/DeliveryLogsAPI';

import { isUserAuthorizedForDenialsPage } from 'Auth/AuthUtils';
import {
  isAvailityClaimStatusCheckEnabledForUser,
  isUHCClaimStatusCheckEnabledForUser,
} from 'Auth/FeatureFlags';

import { startSentryTransaction } from 'helpers/performanceLogging';

import { PAYER_API_TYPE } from 'constants/appConstants';
import { IN_PROGRESS_STATUS } from 'components/Submissions/InProgressSubmissions/constant';

import {
  CREATE_APPEAL_SEARCH_DATA_LOAD_EVENT_NAME,
  GLOBAL_SEARCH_DATA_LOAD_EVENT_NAME,
} from 'constants/performanceLogging';

const SEARCH_LIMIT = 3;
export const DATA_TYPE = {
  APPEAL: 'appeal',
  DENIAL: 'denial',
  ARCHIVE: 'archive',
  ARCHIVE_DENIALS: 'archiveDenials',
  ARCHIVE_ACTION_LOG_TODO: 'archivePending',
  ARCHIVE_ACTION_LOG_COMPLETED: 'archiveCompleted',
  DELIVERY_TRACKER: 'deliveryTracker',
  CLAIM: 'claim',
  ACTION_LOG_TODO: 'actionLogTodo',
  ACTION_LOG_COMPLETED: 'actionLogCompleted',
};

/**
 * Extracts uhc claim status search data from appeal.
 *
 * @param {Object} appeal
 * @param {Object} userInfo
 * @returns {Object | null}
 */
export const getUhcClaimStatusSearchDataForAppeal = (appeal, userInfo) => {
  const isGetClaimStatusAPIEnabled =
    isUHCClaimStatusCheckEnabledForUser(userInfo) &&
    appeal.claimApi === PAYER_API_TYPE.UHC &&
    [appeal.ediPayerId, appeal.claimNumber, appeal.billingProviderTaxId].every(
      (val) => val !== '--'
    );

  if (!isGetClaimStatusAPIEnabled) return null;
  return {
    claimControlNumber: appeal.claimNumber,
    claimNumber: appeal.submitterClaimId,
    billingProviderTaxId: appeal.billingProviderTaxId.replaceAll('-', ''),
    ediPayerId: appeal.ediPayerId,
    appealId: appeal.id,
    renderingProviderName: appeal.providerName,
    billingProviderName: appeal.billingProviderName,
  };
};

export const getAvailityClaimStatusSearchDataForAppeal = (appeal, userInfo) => {
  const isGetClaimStatusAPIEnabled =
    isAvailityClaimStatusCheckEnabledForUser(userInfo) &&
    appeal?.claimApi === PAYER_API_TYPE.AVAILITY &&
    appeal.claimNumber &&
    appeal.claimNumber !== '--';

  if (!isGetClaimStatusAPIEnabled) return null;

  return {
    claimControlNumber: appeal.claimNumber,
    ediPayerId: appeal.ediPayerId,
    appealId: appeal.id,
  };
};

/**
 * Extracts uhc claim status search data from denial.
 * @param {Object} denial
 * @param {Object} userInfo
 * @returns {Object | null}
 */
export const getUHCClaimStatusSearchDataForDenialsQueue = (
  denial,
  userInfo
) => {
  const canUseClaimSearchApi =
    isUHCClaimStatusCheckEnabledForUser(userInfo) &&
    denial.payerApi === PAYER_API_TYPE.UHC &&
    denial.claimControlNumber &&
    denial.billingProviderTaxId &&
    denial.ediPayerId;

  if (!canUseClaimSearchApi) return null;

  return {
    claimControlNumber: denial.claimControlNumber,
    claimNumber: denial.claim,
    billingProviderTaxId: denial.billingProviderTaxId,
    ediPayerId: denial.ediPayerId,
    isClaimSearchViaRpaEnabled: denial.isRpaEnabled,
    billingProviderName: denial.billingProviderName,
    renderingProviderName: denial.providerName,
  };
};

const getAvailityClaimStatusSearchDataForDenialsQueue = (denial, userInfo) => {
  const canUseClaimSearchApi =
    isAvailityClaimStatusCheckEnabledForUser(userInfo) &&
    denial.payerApi === PAYER_API_TYPE.AVAILITY &&
    denial.claimControlNumber;

  if (!canUseClaimSearchApi) return null;

  return {
    claimControlNumber: denial.claimControlNumber,
    ediPayerId: denial.ediPayerId,
  };
};

export const getUHCClaimStatusSearchDataForDeliveryTracker = (
  deliveryTracker,
  userInfo
) => {
  const canUseClaimSearchApi =
    isUHCClaimStatusCheckEnabledForUser(userInfo) &&
    deliveryTracker.payerApi === PAYER_API_TYPE.UHC &&
    deliveryTracker.billingProviderTaxId &&
    deliveryTracker.ediPayerId &&
    deliveryTracker.claimNumber;

  if (!canUseClaimSearchApi) return null;

  return {
    claimControlNumber: deliveryTracker.claimNumber,
    billingProviderTaxId: deliveryTracker.billingProviderTaxId.replaceAll(
      '-',
      ''
    ),
    ediPayerId: deliveryTracker.ediPayerId,
    claimNumber: deliveryTracker.submitterClaimId,
  };
};

const getAvailityClaimStatusSearchDataForDeliveryTracker = (
  deliveryTracker,
  userInfo
) => {
  const canUseClaimSearchApi =
    isAvailityClaimStatusCheckEnabledForUser(userInfo) &&
    deliveryTracker.payerApi === PAYER_API_TYPE.AVAILITY &&
    deliveryTracker.claimNumber;

  if (!canUseClaimSearchApi) return null;

  return {
    claimControlNumber: deliveryTracker.claimNumber,
    ediPayerId: deliveryTracker.ediPayerId,
    appealId: deliveryTracker.appealId,
  };
};

/**
 * Extracts uhc claim status search data from claim.
 * @param {Object} claim
 * @param {Objejct} userInfo
 * @returns {Object | null}
 */
export const getUHCClaimStatusSearchDataForClaims = (claim, userInfo) => {
  const canUseClaimSearchApi =
    isUHCClaimStatusCheckEnabledForUser(userInfo) &&
    claim.payerApi === PAYER_API_TYPE.UHC &&
    claim.claimControlNumber &&
    claim.billingProviderTaxId &&
    claim.ediPayerId;

  if (!canUseClaimSearchApi) return null;

  return {
    claimControlNumber: claim.claimControlNumber,
    claimNumber: claim.claimNumber,
    billingProviderTaxId: claim.billingProviderTaxId,
    ediPayerId: claim.ediPayerId,
    isClaimSearchViaRpaEnabled: claim.isRpaEnabled,
    billingProviderName: claim.billingProviderName,
    renderingProviderName: claim.providerName,
  };
};

const getAvailityClaimStatusSearchDataForClaims = (claim, userInfo) => {
  const canUseClaimSearchApi =
    isAvailityClaimStatusCheckEnabledForUser(userInfo) &&
    claim.payerApi === PAYER_API_TYPE.AVAILITY &&
    claim.claimControlNumber;

  if (!canUseClaimSearchApi) return null;

  return {
    claimControlNumber: claim.claimControlNumber,
    ediPayerId: claim.ediPayerId,
  };
};

/**
 * Formats appeals response.
 * @param {Object} data
 * @param {Boolean} isMedicalRecord
 * @param {Object} userInfo
 * @returns {Array<{patientName: String, claimNumber: String, eobDate: String, uhcClaimStatusSearchData: Object | null}>
 */
const formatAppealResponse = (data, isMedicalRecord = false, userInfo) => {
  return data.map(({ appeals }) => {
    const [appeal] = appeals;
    return {
      patientName: appeal.patientName,
      claimNumber: isMedicalRecord
        ? appeal.submitterClaimId
        : appeal.claimNumber,
      submitterClaimId: appeal.submitterClaimId,
      eobDate: appeal.deniedAt,
      availityClaimStatusSearchData: getAvailityClaimStatusSearchDataForAppeal(
        appeal,
        userInfo
      ),
      uhcClaimStatusSearchData: getUhcClaimStatusSearchDataForAppeal(
        appeal,
        userInfo
      ),
    };
  });
};

/**
 * Fetches appeals from the API.
 * @param {String} searchTerm
 * @returns {Object<{data: Array<{name: String, claimNumber: String}>, count: Number}>}
 */
const fetchAppeals = async (searchTerm, userInfo) => {
  const { data, totalRows } = await AppealsAPI.getAppealsOnPage(1, [
    { name: 'search', query: searchTerm, useAsURLParam: true },
    { name: 'limit', query: SEARCH_LIMIT, useAsURLParam: true },
    { name: 'status', query: IN_PROGRESS_STATUS, useAsURLParam: true },
  ]);

  return {
    data: formatAppealResponse(data, false, userInfo),
    count: totalRows,
  };
};

const fetchDeliveryTracker = async (searchTerm, userInfo) => {
  const { results, count } = await fetchDeliveryLogs({
    search: searchTerm,
    disable_tag_search: 1,
    limit: SEARCH_LIMIT,
    ordering: 'patient',
  });

  const data = results
    .map((result) => {
      const { patient: patientName, claimNumber, deniedAt: eobDate } = result;

      return {
        patientName,
        claimNumber,
        eobDate,
        availityClaimStatusSearchData:
          getAvailityClaimStatusSearchDataForDeliveryTracker(result, userInfo),
        uhcClaimStatusSearchData: getUHCClaimStatusSearchDataForDeliveryTracker(
          result,
          userInfo
        ),
      };
    })
    .filter(
      ({ patientName, claimNumber, eobDate }) =>
        patientName?.trim() || claimNumber?.trim() || eobDate?.trim()
    ); // Filter out standalone submission data

  const filteredCount = data.length < count ? data.length : count;

  return {
    data,
    count: filteredCount,
  };
};

/**
 * Fetches denials from the API.
 * @param {String} searchTerm
 * @param {Object} userInfo
 * @returns {Promise<Array<{patientName: String, claimNumber: String, uhcClaimStatusSearchData: Object | null}>>}
 */
const fetchDenialQueueItems = async (
  searchTerm,
  userInfo,
  fetchArchivedItems = false
) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      data: [],
      count: 0,
    };
  }

  const { rows, total } = await DenialsAPI.fetchDenials({
    filters: {
      search: searchTerm,
    },
    limit: SEARCH_LIMIT,
    fetchArchivedItems,
  });

  const data = rows.map((row) => {
    const {
      patientName,
      ediPaymentDate: eobDate,
      claim: submitterClaimId,
      practiceId: practiceIdentifier,
      claimControlNumber: claimNumber,
      manualClaimId,
    } = row;

    return {
      patientName,
      claimNumber,
      submitterClaimId,
      practiceIdentifier,
      eobDate,
      availityClaimStatusSearchData:
        getAvailityClaimStatusSearchDataForDenialsQueue(row, userInfo),
      uhcClaimStatusSearchData: getUHCClaimStatusSearchDataForDenialsQueue(
        row,
        userInfo
      ),
      manualClaimId,
    };
  });

  return {
    data,
    count: total,
  };
};

/**
 * Fetches claims from the API.
 * @param {String} searchTerm
 * @param {Object} userInfo
 * @returns {Promise<Array<{patientName: String, claimNumber: String}>>}
 */
const fetchClaims = async (searchTerm, userInfo) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      data: [],
      count: 0,
    };
  }

  const { rows, total } = await DenialsAPI.fetchDeniedClaims({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    limit: SEARCH_LIMIT,
  });

  const data = rows.map((row) => {
    const {
      patientName,
      practiceIdentifier,
      ediPaymentDate: eobDate,
      claimNumber: submitterClaimId,
      claimControlNumber: claimNumber,
    } = row;

    return {
      patientName,
      claimNumber,
      submitterClaimId,
      practiceIdentifier,
      eobDate,
      availityClaimStatusSearchData: getAvailityClaimStatusSearchDataForClaims(
        row,
        userInfo
      ),
      uhcClaimStatusSearchData: getUHCClaimStatusSearchDataForClaims(
        row,
        userInfo
      ),
    };
  });

  return {
    data,
    count: total,
  };
};

/**
 * Fetches action logs from the API.
 * @param {String} searchTerm
 * @param {Number} completed
 * @param {Object} userInfo
 * @returns {Promise<Array<{patientName: String, claimNumber: String}>>
 */
const fetchActionLogs = async (
  searchTerm,
  completed = 0,
  userInfo,
  fetchArchivedItems = false
) => {
  if (!isUserAuthorizedForDenialsPage(userInfo)) {
    return {
      data: [],
      count: 0,
    };
  }

  const { rows, counts } = await DenialsAPI.fetchDenials({
    search: [
      {
        key: 'search',
        value: searchTerm,
      },
    ],
    limit: SEARCH_LIMIT,
    completed,
    action: 1,
    fetchArchivedItems,
  });

  const data = rows.map(
    ({ claimControlNumber, patientName, ediPaymentDate: eobDate }) => ({
      patientName,
      claimNumber: claimControlNumber,
      eobDate,
    })
  );

  return {
    data,
    count: counts,
  };
};

/**
 * Fetches data from the API.
 * @param {String} searchTerm
 * @param {Object} userInfo
 * @returns {Promise<Array<{type: String, data: Array<{patientName: String, claimNumber: String, uhcClaimStatusSearchData: Object | null}>, count: Number}>>}
 **/
const fetchData = async (searchTerm, userInfo) => {
  const [
    appeals,
    deliveryTracker,
    denials,
    archivedDenials,
    claims,
    completedActionLogs,
    archivedCompletedActionLogs,
    todoActionLogs,
    archivedTodoActionLogs,
  ] = await Promise.all([
    fetchAppeals(searchTerm, userInfo),
    fetchDeliveryTracker(searchTerm, userInfo),
    fetchDenialQueueItems(searchTerm, userInfo),
    fetchDenialQueueItems(searchTerm, userInfo, true),
    fetchClaims(searchTerm, userInfo),
    fetchActionLogs(searchTerm, 1, userInfo),
    fetchActionLogs(searchTerm, 1, userInfo, true),
    fetchActionLogs(searchTerm, 0, userInfo),
    fetchActionLogs(searchTerm, 0, userInfo, true),
  ]);

  return [
    {
      type: DATA_TYPE.APPEAL,
      data: appeals.data,
      count: appeals.count,
    },
    {
      type: DATA_TYPE.DELIVERY_TRACKER,
      data: deliveryTracker.data,
      count: deliveryTracker.count,
    },
    {
      type: DATA_TYPE.DENIAL,
      data: denials.data,
      count: denials.count,
    },
    {
      type: DATA_TYPE.CLAIM,
      data: claims.data,
      count: claims.count,
    },
    {
      type: DATA_TYPE.ACTION_LOG_COMPLETED,
      data: completedActionLogs.data,
      count: completedActionLogs.count.completed,
    },
    {
      type: DATA_TYPE.ACTION_LOG_TODO,
      data: todoActionLogs.data,
      count: todoActionLogs.count.todo,
    },
    {
      type: DATA_TYPE.ARCHIVE_DENIALS,
      data: archivedDenials.data,
      count: archivedDenials.count,
    },
    {
      type: DATA_TYPE.ARCHIVE_ACTION_LOG_COMPLETED,
      data: archivedCompletedActionLogs.data,
      count: archivedCompletedActionLogs.count.completed,
    },
    {
      type: DATA_TYPE.ARCHIVE_ACTION_LOG_TODO,
      data: archivedTodoActionLogs.data,
      count: archivedTodoActionLogs.count.todo,
    },
  ].filter(({ data }) => data.length > 0);
};

const fetchDataForAppealCreation = async (searchTerm, userInfo) => {
  const [denials, archivedDenials, claims] = await Promise.all([
    fetchDenialQueueItems(searchTerm, userInfo),
    fetchDenialQueueItems(searchTerm, userInfo, true),
    fetchClaims(searchTerm, userInfo),
  ]);

  return [
    {
      type: DATA_TYPE.DENIAL,
      data: denials.data,
      count: denials.count,
    },
    {
      type: DATA_TYPE.ARCHIVE_DENIALS,
      data: archivedDenials.data,
      count: archivedDenials.count,
    },
    {
      type: DATA_TYPE.CLAIM,
      data: claims.data,
      count: claims.count,
    },
  ].filter(({ data }) => data.length > 0);
};

export const useFetchSearchResults = (
  searchTerm,
  userInfo,
  isAppealCreation = false,
  limitSearchStringLength = 0
) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      setError(null);
      setData([]);
      const sentryTransaction = startSentryTransaction(
        isAppealCreation
          ? CREATE_APPEAL_SEARCH_DATA_LOAD_EVENT_NAME
          : GLOBAL_SEARCH_DATA_LOAD_EVENT_NAME,
        userInfo
      );
      try {
        if (
          isEmpty(searchTerm) ||
          searchTerm.length < limitSearchStringLength
        ) {
          setLoading(false);
          return;
        }

        const data = !isAppealCreation
          ? await fetchData(searchTerm, userInfo)
          : await fetchDataForAppealCreation(searchTerm, userInfo);
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
        sentryTransaction?.finish();
      }
    };
    fetch();

    return () => {
      setLoading(false);
      setError(null);
      setData([]);
    };
  }, [searchTerm, userInfo, isAppealCreation, limitSearchStringLength]);

  return { loading, error, data, fetch };
};
