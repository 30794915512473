import numbro from 'numbro';
import { validateAmountFormat } from './validators';

numbro.culture('en-US');
numbro.defaultCurrencyFormat('0,0[.]00');

export const parseMoney = (value) => {
  if (!value) {
    return value;
  }

  if (validateAmountFormat(value)) {
    return null;
  }
  return numbro().unformat(value.replace(/,/g, ''));
};

export const parseMoneyInput = (value) => {
  if (!value) {
    return 0;
  } else {
    return parseMoney(value.replace('$', '').replace(/,/g, ''));
  }
};

export function sumMoneyInputs(values) {
  let sum = 0;

  for (let i = 1; i < arguments.length; i++) {
    sum += parseMoneyInput(values.get(arguments[i]));
  }

  sum = +sum.toFixed(2);

  return sum;
}

export const formatToMoney = (value, includeCent = false) => {
  if (value == null) {
    return value;
  }

  if (includeCent) {
    return numbro(value).formatCurrency('0,0.00');
  }
  return numbro(value).formatCurrency();
};

export const sumMoney = (array, fieldName) => {
  return array
    .reduce((total, value) => {
      const amount = value.get(fieldName, 0) || 0;
      const parsedMoney = parseMoney(amount);
      return total + parsedMoney;
    }, 0)
    .toFixed(2);
};

/**
 * Money Formatter is a function that will take in an override object to allow
 * overrides to be able to format into many different money formats.
 *
 * @param {override} {Object} An object to take in different possible formats needed for each use case.
 *
 * return string of the formatted amount.
 *
 * How to use > moneyFormatter([any format you may need]).format([the value that you would like to be converted into money])
 */
export const moneyFormatter = (override) => {
  let defaultConfig = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  if (override && Object.keys(override).length) {
    defaultConfig = { ...defaultConfig, ...override };
  }

  return new Intl.NumberFormat('en-US', defaultConfig);
};

export const floatNormalizer = (value) => {
  if (!value) return null;
  value = value.replace(/[^0-9.]/g, '');
  const floatValue = parseFloat(value);
  return isNaN(floatValue) ? null : floatValue;
};
