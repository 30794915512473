import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { useState, useMemo, useEffect } from 'react';
import { omit, isEmpty, pickBy, identity } from 'lodash';

import Button from 'components/common/button';
import Avatar from 'components/Shared/Avatar';
import { snakeCaseKeys } from 'helpers/object';
import Dropdown from 'components/Shared/Dropdown';
import SearchBox from 'components/common/search/SearchBox';
import { BUTTON_TYPE } from 'components/common/button/Button';
import DownloadCSV from 'components/common/button/DownloadCSV';
import LoadingBlockHelper from 'components/Shared/LoadingBlockHelper';
import TablePagination from 'components/common/pagination/TablePagination';
import InProgressSubmissionPopupFilter from './InProgressSubmissionPopupFilter';

import settingsIcon from 'img/settings.svg';
import closeIcon from 'img/close-icon-circle.svg';
import filterIconRed from 'img/filter-icon-red.svg';
import searchIcon from 'img/search-icon-white.svg';
import searchIconRed from 'img/search-icon-red.svg';

import AppealsAPI from 'API/AppealsAPI';

import { USER_ROLES } from 'constants/appConstants';
import { TYPE_OPTIONS, IN_PROGRESS_STATUS } from './constant';

import {
  createObjectMap,
  getPageCountMessage,
  updateFilterOptions,
} from 'helpers/utils';
import { handleError } from 'helpers/errorHandler';

import { useFetchInProgressOptionsCounts } from './hooks';
import { useFetchDeliveryLogsOptions } from '../DeliveryTracking/hooks';

const getSelectedValue = (options, value) =>
  options.find((option) => option.value === value) || null;

const InProgressSubmissionActionBar = (props) => {
  const {
    filters,
    isResetActive,
    handleResetClick,
    data,
    totalSubmissions,
    pageCount,
    page,
    onPageChange,
    userInfo,
    onFilterChange,
    defaultFilters,
    agentsList,
    selectedAppeal,
    setDeleteSubmissionPopup,
  } = props;
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const [showPopupFilter, setShowPopupFilter] = useState(false);

  const { loading, options } = useFetchDeliveryLogsOptions(false, userInfo);

  useEffect(() => {
    agentsList(options?.agents);
  }, [agentsList, options?.agents]);

  const { inProgressDropdownWithCounts } =
    useFetchInProgressOptionsCounts(filters);

  /**
   * Generate dropdown options with counts for In-Progress submissions.
   *
   * @param {object} inProgressDropdownWithCounts - dropdown options with counts for in progress Submissions
   * @return {object}
   */
  const addDropdownWithCounts = (inProgressDropdownWithCounts) => {
    if (isEmpty(inProgressDropdownWithCounts)) {
      return {
        payerDropdownOptionsCounts: options.payers,
        providersDropdownOptionsCount: options.providers,
        agentsDropdownOptionsCount: options.agents,
        practiceDropdownOptionsCounts: options.practices,
      };
    }

    const { payerCounts, providerCounts, agentCounts, practiceCounts } =
      inProgressDropdownWithCounts;

    const payerDropdownOptionsCounts = updateFilterOptions(
      options.payers,
      createObjectMap(payerCounts, 'id', 'count'),
      'value',
      'label'
    );

    const agentsDropdownOptionsCount = updateFilterOptions(
      options.agents,
      createObjectMap(agentCounts, 'id', 'count'),
      'value',
      'label'
    );

    const practiceDropdownOptionsCounts = updateFilterOptions(
      options.practices,
      createObjectMap(practiceCounts, 'practiceIdentifier', 'count'),
      'practiceIdentifier',
      'label'
    );

    const providersDropdownOptionsCount = updateFilterOptions(
      options.providers,
      createObjectMap(providerCounts, 'id', 'count'),
      'value',
      'label'
    );

    return {
      payerDropdownOptionsCounts,
      providersDropdownOptionsCount,
      agentsDropdownOptionsCount,
      practiceDropdownOptionsCounts,
    };
  };

  const {
    payerDropdownOptionsCounts,
    providersDropdownOptionsCount,
    agentsDropdownOptionsCount,
    practiceDropdownOptionsCounts,
  } = addDropdownWithCounts(inProgressDropdownWithCounts);

  const dropdownOptions = {
    payers: payerDropdownOptionsCounts,
    providers: providersDropdownOptionsCount,
    agents: agentsDropdownOptionsCount,
    practices: practiceDropdownOptionsCounts,
  };

  const onExportCSVPressed = async () => {
    setIsExportingCSV(true);
    const params = {
      ...filters,
      status: IN_PROGRESS_STATUS,
    };
    const fileName = `${userInfo.clientName} Appeals - ${moment().format(
      'MM/DD/YYYY'
    )}.csv`;
    try {
      await AppealsAPI.downloadAppealsExport(
        pickBy(snakeCaseKeys(params), identity),
        fileName,
        'text/csv'
      );
    } catch (error) {
      handleError(error);
    }
    setIsExportingCSV(false);
  };

  const handleFilterChange = (key, value) =>
    onFilterChange({
      ...props.filters,
      [key]: value,
    });

  const getFilteredValues = (obj, excludeKeys = []) => {
    const filteredObject = omit(obj, excludeKeys);
    return Object.values(filteredObject).filter(
      (value) =>
        ![null, '', undefined, false].includes(value) &&
        (!Array.isArray(value) || value.length > 0)
    );
  };

  const isFiltersApplied = getFilteredValues(filters, ['search']).length > 0;
  const appliedFiltersCount = getFilteredValues(filters, ['search']).length;

  const inProgressCountOnPage = data.length;
  const InProgressText =
    inProgressCountOnPage === 0 || inProgressCountOnPage > 1
      ? 'Submissions'
      : 'Submission';
  const inProgressSubmissionsCountText =
    inProgressCountOnPage > 0
      ? `${getPageCountMessage(
          page,
          inProgressCountOnPage,
          totalSubmissions
        )} ${InProgressText}`
      : `${totalSubmissions} ${InProgressText}`;

  const userPractices = userInfo.relatedPractices || [];
  const typeOptionsWithCounts = useMemo(() => {
    return TYPE_OPTIONS.map((option) => {
      if (option.value === 'appeal') {
        return {
          ...option,
          label: `Appeal (${inProgressDropdownWithCounts.appealsCount})`,
        };
      }
      if (option.value === 'record') {
        return {
          ...option,
          label: `Record (${inProgressDropdownWithCounts.recordsCount})`,
        };
      }
      return option;
    });
  }, [inProgressDropdownWithCounts]);

  const actionButtonTitle = selectedAppeal?.isMedicalRecordsSubmission
    ? 'Delete Record'
    : 'Delete Appeal';

  return (
    <div
      className={classnames(
        'appealio-table__action-bar appealio-table__action-bar--in-progress row no-gutter'
      )}
    >
      <div className="d-flex align-items-center justify-content--space-between col-md-6">
        {selectedAppeal && (
          <Button
            title={actionButtonTitle}
            type={BUTTON_TYPE.SECONDARY}
            iconClassName="mr-4"
            icon={closeIcon}
            className="ml-12"
            onClick={() =>
              setDeleteSubmissionPopup({
                isOpen: true,
                appeal: selectedAppeal,
              })
            }
          />
        )}
        <div className="d-flex align-items-center ml-auto">
          <Dropdown
            hideLabel
            placeholder="Type"
            options={typeOptionsWithCounts}
            className="width-180 mr-16"
            value={getSelectedValue(
              typeOptionsWithCounts,
              filters.submissionType
            )}
            onChange={(option) =>
              handleFilterChange('submissionType', option.value)
            }
          />
        </div>
      </div>

      <div className="d-flex align-items-center col-md-2">
        <Button
          title=""
          icon={filters.search ? searchIconRed : searchIcon}
          className="mr-16"
          type={BUTTON_TYPE.SECONDARY}
          onClick={() => setShowSearchBox(!showSearchBox)}
          datacy="more-filters-search-Button"
        />

        <Button
          icon={isFiltersApplied ? filterIconRed : settingsIcon}
          className="appealio-filter-button"
          type={BUTTON_TYPE.SECONDARY}
          onClick={() => setShowPopupFilter(true)}
          datacy="more-filters-search-Button"
        >
          {appliedFiltersCount ? (
            <Avatar
              withBorder={false}
              name={appliedFiltersCount.toString()}
              size={16}
              shouldRenderFullName={true}
              datacy="denial-queue-container-Avatar"
            />
          ) : null}
        </Button>
        <div
          onClick={handleResetClick}
          className={classnames({
            'visibility-hidden': !isResetActive,
            'denial-table__filter-clear d-inline': isResetActive,
          })}
        >
          Reset
        </div>
      </div>

      {showSearchBox && (
        <SearchBox
          value={filters.search}
          onSearch={(value) =>
            onFilterChange({
              ...props.filters,
              search: value,
            })
          }
        />
      )}

      <div className="appealio-table__action-bar-pagination col-md-5">
        {!showSearchBox && (
          <>
            <div className="in-progress-submissions-actions-wrapper">
              <LoadingBlockHelper isLoading={loading}>
                <DownloadCSV
                  onClick={onExportCSVPressed}
                  disabled={
                    userInfo.role === USER_ROLES.CLIENT_ADMIN &&
                    data.totalRows < 1
                  }
                  isLoading={isExportingCSV}
                />
              </LoadingBlockHelper>
            </div>
            <span className="denial-table-count">
              <span className="searchbar__counter">
                {inProgressSubmissionsCountText}
              </span>
            </span>
          </>
        )}
        {totalSubmissions && pageCount > 1 ? (
          <div className="d-flex justify-content-center">
            <TablePagination
              pageCount={pageCount}
              activePage={page}
              onPageChange={onPageChange}
              datacy="denial-queue-container-Pagination"
            />
          </div>
        ) : null}
      </div>

      {showPopupFilter && (
        <InProgressSubmissionPopupFilter
          setShowPopupFilter={setShowPopupFilter}
          filters={filters}
          onFilterChange={onFilterChange}
          options={dropdownOptions}
          defaultFilters={defaultFilters}
          getSelectedValue={getSelectedValue}
          userPractices={userPractices}
          loading={loading}
        />
      )}
    </div>
  );
};

InProgressSubmissionActionBar.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  isResetActive: PropTypes.bool,
  handleResetClick: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  totalSubmissions: PropTypes.number,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  userInfo: PropTypes.object,
  onFilterChange: PropTypes.func,
  defaultFilters: PropTypes.object,
};

export default InProgressSubmissionActionBar;
