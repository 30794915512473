import React, { Component } from 'react';
import { FieldArray } from 'redux-form/immutable';
import { Map } from 'immutable';
import {
  renderCPTS,
  renderCPTSDisabled,
  renderCPTSPartiallyDisabled,
  renderCPTSWorkers,
  renderCPTSWorkersPartiallyDisabled,
} from './CPTListRender';
import TotalAmounts from './TotalAmounts';
import { CreateAppealMode } from './CreateAppealMode';

const renderModeMapping = {
  [CreateAppealMode.ReAppeal]: renderCPTSPartiallyDisabled,
  [CreateAppealMode.NewAppeal]: renderCPTS,
  [CreateAppealMode.HistoryAppeal]: renderCPTSDisabled,
  [CreateAppealMode.Resume]: renderCPTS,
};

const renderModeWorkersPayerMapping = {
  [CreateAppealMode.HistoryAppeal]: renderCPTSDisabled,
  [CreateAppealMode.ReAppeal]: renderCPTSWorkersPartiallyDisabled,
  [CreateAppealMode.NewAppeal]: renderCPTSWorkers,
  [CreateAppealMode.Resume]: renderCPTSWorkers,
};

export default class CPTList extends Component {
  render() {
    return this.renderFields();
  }

  extractCPTSRenderMethod() {
    if (this.props.isImportedAppeal === true) {
      return renderCPTS;
    }

    return this.props.isWorkerCompensationPayer === true
      ? renderModeWorkersPayerMapping[this.props.mode]
      : renderModeMapping[this.props.mode];
  }

  renderFields() {
    const renderMethod = this.extractCPTSRenderMethod();

    return (
      <table
        className="cpt__table denial-info-offset-spacing"
        datacy="cptTable"
      >
        <FieldArray
          name="cpts"
          component={renderMethod}
          isImportedAppeal={this.props.isImportedAppeal}
          integrationType={this.props.integrationType}
        />

        <tbody>
          <TotalAmounts
            {...this.props.sums}
            isImportedAppeal={this.props.isImportedAppeal}
            integrationType={this.props.integrationType}
          />
        </tbody>
      </table>
    );
  }

  addNewBlankCPT = () => {
    this.props.array.push(
      'cpts',
      Map({
        quantity: 1,
      })
    );
  };
}
