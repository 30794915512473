import moment from 'moment';
import PropTypes from 'prop-types';
import { BiX } from 'react-icons/bi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { MdInfoOutline } from 'react-icons/md';
import React, { useEffect, useCallback } from 'react';

import './style.css';

import Select from 'components/common/select/Select';
import ReduxSingleDatePicker from 'components/common/datepicker/ReduxSingleDatePicker';
import CurrencyInput from 'components/common/input/CurrencyInput';
import ActionButton from 'components/common/actionButton/ActionButton';

import saveIcon from 'img/save-icon.svg';
import calendar from 'img/calendar-icon.svg';

import { floatNormalizer } from 'helpers/money';
import { VALIDATION_DATE_FORMAT } from 'helpers/dateFormats';

import { Field, reset, reduxForm } from 'redux-form/immutable';

let AddOrEditFeeScheduleForm = (props) => {
  const {
    onSubmit,
    handleSubmit,
    cptCodes,
    initialize,
    initialValues,
    isCptCodesLoading,
    closeAddFeeScheduleForm,
  } = props;

  const getSelectedCptCodeOption = useCallback(
    (cptCode) => cptCodes.find((option) => option.value === cptCode),
    [cptCodes]
  );

  useEffect(() => {
    if (initialValues) {
      const values = initialValues.toJS();
      const selectedCptCode = getSelectedCptCodeOption(values.cptCode);
      initialize({
        ...values,
        cptCode: selectedCptCode,
        allowedAmount: floatNormalizer(values.allowedAmount),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <>
      <tr className="add-fee-schedule-form fee-schedule-table__tr">
        <td className="fee-schedule-table__td">
          <Field
            component={Select}
            name="cptCode"
            placeholder="Select Procedure"
            options={cptCodes}
            isLoading={isCptCodesLoading}
            classNamePrefix="fee-schedule-select"
            reactSelectProps={{
              maxMenuHeight: 150,
              menuPlacement: 'bottom',
              menuPortalTarget: document.body,
            }}
          />
        </td>
        <td className="fee-schedule-table__td">
          <div className="position-relative">
            <Field
              component={CurrencyInput}
              name="allowedAmount"
              normalize={floatNormalizer}
              placeholder="$00.00"
            />
            <MdInfoOutline
              size="18"
              className="ml-8 fee-schedule-table__info-icon"
              data-tip="Enter expected reimbursement amount per payer contract"
            />
            <ReactTooltip effect="solid" place="top" />
          </div>
        </td>
        <td className="fee-schedule-table__td">
          <Field
            component={ReduxSingleDatePicker}
            name="expirationDate"
            placeholder="MM/DD/YYYY"
            block={true}
            displayFormat={VALIDATION_DATE_FORMAT}
            verticalSpacing={10}
            isOutsideRange={(d) => d.isBefore(moment(), 'day')}
            customInputIcon={
              <img className="form-icon" src={calendar} alt="Calendar" />
            }
          />
        </td>
        <td colSpan={2} className="fee-schedule-table__td">
          <div className="d-flex align-items-center justify-content-flex-end">
            <div
              className="cursor-pointer d-flex align-items-center"
              onClick={handleSubmit(onSubmit)}
            >
              <img src={saveIcon} alt="save" />
              Save
            </div>
            <ActionButton
              Icon={BiX}
              className="ml-16 ap-button--action-delete"
              dataTip="Cancel"
              onClick={(e) => {
                e.preventDefault();
                closeAddFeeScheduleForm();
              }}
              iconClassName="appeal__action--appeal"
              datacy="practice-deactivate-ActionButton"
            />
          </div>
        </td>
      </tr>
      <tr className="spacer fee-schedule-table__tr">
        <td colSpan="100" className="fee-schedule-table__td"></td>
      </tr>
    </>
  );
};

AddOrEditFeeScheduleForm.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  cptCodes: PropTypes.array,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
  isCptCodesLoading: PropTypes.bool,
  closeAddFeeScheduleForm: PropTypes.func,
};

AddOrEditFeeScheduleForm = reduxForm({
  form: 'addOrEditFeeScheduleForm',
  enableReinitialize: false,
})(AddOrEditFeeScheduleForm);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: () => dispatch(reset('addOrEditFeeScheduleForm')),
  };
};

AddOrEditFeeScheduleForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrEditFeeScheduleForm);

export default AddOrEditFeeScheduleForm;
