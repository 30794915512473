import { flatMap } from 'lodash';

import {
  handleErrors,
  BASE_API_URL,
  createRequest,
  handleJSONParse,
  createJSONFetch,
  createGetRequest,
  INTEGRATION_API_BASE_URL,
} from './Config';

import { interpolate } from 'helpers/string';
import { camelizeKeys } from '../helpers/object';

import * as endpoints from '../constants/endpoints';

import { fetchDeliveryLogs } from './DeliveryLogsAPI';

/**
 * Fetch an appeal by id, including nested letters and payer information.
 * @param {number} id - The id of the appeal to fetch
 * @returns {Promise} A promise resolving to the appeal data
 */
const getAppealById = (id) => {
  const request = createGetRequest(`appeals/${id}`, {
    include: ['letters', 'payer', 'payerContact', 'eob.eob_procedures'],
  });

  return createJSONFetch(request);
};

/**
 * Fetch practices with optional query parameters.
 * @param {Object} queryParam - Optional query parameters to filter by
 * @returns {Promise} A promise resolving to the list of practices
 */
export const fetchPractices = async (queryParam) => {
  const url = endpoints.PRACTICES;
  const request = createGetRequest(
    url,
    {
      ...queryParam,
    },
    BASE_API_URL
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Creates a new practice group.
 * @param {Object} data - The data for the new practice group
 * @returns {Promise} A promise resolving to the newly created practice group
 */
export const createPracticeGroup = async (data) => {
  const url = `${endpoints.PRACTICE_GROUPS}`;

  const request = createRequest(url, data, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetches submission data for an appeal
 * @param {number} appealId - The id of the appeal to fetch data for
 * @returns {Promise} A promise resolving to an object containing the submission data
 * @property {number} appealId - The id of the appeal
 * @property {number[]} appealLetterTemplateIds - The ids of the appeal letter templates
 * @property {number[]} appealFormTemplateIds - The ids of the appeal form templates
 * @property {string} claimControlNumber - The claim control number
 * @property {number} claimId - The id of the claim
 * @property {string} method - The method of submission
 * @property {number} payerId - The id of the payer
 * @property {string} payerName - The name of the payer
 */
export const getSubmissionData = async (appealId) => {
  const [appealData, { results }] = await Promise.all([
    getAppealById(appealId),
    fetchDeliveryLogs({ appeal_id: appealId }),
  ]);

  const appealLetterTemplateIds = [];
  const appealFormTemplateIds = [];

  appealData?.included?.forEach((item) => {
    if (item.type === 'AppealLetter') {
      const templateId = item?.relationships?.template?.data?.id;
      if (item.attributes.letter_type === 'html') {
        appealLetterTemplateIds.push(templateId);
      } else if (item.attributes.letter_type === 'pdf') {
        appealFormTemplateIds.push(templateId);
      }
    }
  });

  const payerContact = camelizeKeys(
    appealData?.included?.find((item) => item.type === 'PayerContact')
      ?.attributes || {}
  );

  const getUniqueCodes = (arr1 = [], arr2 = []) => {
    return [...new Set([...arr1, ...arr2])];
  };

  const eobProcedures =
    camelizeKeys(appealData)
      ?.included?.filter((item) => item.type === 'EOBProcedure')
      .map((item) => item?.attributes) || [];

  const appealReasonCodes = flatMap(eobProcedures, (item) =>
    flatMap(item.adjCodes, (x) => x.split(','))
  );
  const appealRemarkCodes = flatMap(eobProcedures, (item) => item.remarkCodes);

  const {
    claim_number: claimControlNumber,
    submitter_claim_id: claimId,
    payer_id: payerId,
    payer_name: payerName,
    is_medical_records_submission: isMedicalRecord,
  } = appealData?.data?.attributes || {};

  const deliveryMethod = results?.[0]?.method;

  return {
    appealId,
    appealLetterTemplateIds,
    appealFormTemplateIds,
    claimControlNumber,
    claimId,
    deliveryMethod,
    payerId,
    payerName,
    isMedicalRecord,
    payerContact,
    reasonCodes: getUniqueCodes(appealReasonCodes),
    remarkCodes: getUniqueCodes(appealRemarkCodes),
  };
};
/**
 * Fetches denials and claims.
 *
 * @param {Object} params
 * @returns {Promise}
 */
export const fetchDenialsAndClaims = async (params) => {
  const request = createRequest(
    endpoints.DENIALS_AND_CLAIMS,
    {},
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'GET',
      params,
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Makes a POST request to the batch import endpoint.
 *
 * @param {Object} values
 * @returns {Promise}
 */
export const batchImportClaims = async (values) => {
  const request = createRequest(endpoints.BATCH_IMPORT, values, {
    baseUrl: BASE_API_URL,
    method: 'POST',
  });

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Makes a GET request to fetch the status of a batch import job.
 *
 * @param {String} jobId - The id of the batch import job to fetch the status of.
 * @returns {Promise} A promise that resolves with the status of the batch
 * import job.
 */
export const getBatchJobStatus = async (jobId) => {
  const request = createRequest(
    interpolate(endpoints.BATCH_IMPORT_STATUS, {
      jobId,
    }),
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'GET',
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Makes a GET request to fetch batch jobs that were duplicated from an appeal.
 *
 * @param {String} appealId - The ID of the appeal to fetch duplicated batch jobs for
 * @returns {Promise} A promise that resolves with an array of batch job IDs
 */
export const getDuplicatedBatchJobs = async (appealId) => {
  const request = createRequest(
    interpolate(endpoints.BATCH_JOBS_DUPLICATED_FROM_APPEAL, {
      appealId,
    }),
    {},
    {
      baseUrl: BASE_API_URL,
      method: 'GET',
    }
  );

  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};
