import { identity, pickBy, get } from 'lodash';
import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  createRequest,
  BASE_API_URL,
} from './Config';

import {
  UCH_CLAIM_STATUS,
  UHC_CLAIM_STATUS_HISTORY,
  UHC_CLAIM_STATUS_SCHEDULE,
  AVAILITY_CLAIM_STATUS,
  AVAILITY_CLAIM_STATUS_PAYER_CONFIG,
  AVAILITY_CLAIM_STATUS_PREFILL_DATA,
  AVAILITY_CLAIM_STATUS_SCHEDULE,
} from 'constants/endpoints';

import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

const getRank = (
  containsAppealioClaimNumber = false,
  statusCategoryCode = ''
) => {
  if (
    containsAppealioClaimNumber &&
    statusCategoryCode?.toLowerCase().startsWith('f')
  )
    return 1;
  if (
    containsAppealioClaimNumber &&
    statusCategoryCode?.toLowerCase().startsWith('a')
  )
    return 2;
  if (containsAppealioClaimNumber) return 3;
  if (
    !containsAppealioClaimNumber &&
    statusCategoryCode?.toLowerCase().startsWith('f')
  )
    return 4;
  return 5;
};

export const sortAvailityClaimStatuses = (
  claimStatuses = [],
  appealioClaimNumber
) => {
  const claimStatusWithRank = claimStatuses.reduce((acc, claimStatus) => {
    const statusDetail = get(claimStatus, 'statusDetails[0]');
    const claimNumber = get(claimStatus, 'claimControlNumber', '');

    const containsAppealioClaimNumber = appealioClaimNumber?.includes(
      claimNumber.replace(' ', '')
    );

    return [
      ...acc,
      {
        claimStatus,
        rank: !claimNumber?.replace(' ', '')
          ? Number.MAX_VALUE
          : getRank(
              containsAppealioClaimNumber,
              get(statusDetail, 'categoryCode')
            ),
      },
    ];
  }, []);

  return claimStatusWithRank
    .sort((a, b) => a.rank - b.rank)
    .map((claimStatus) => claimStatus.claimStatus);
};

export const fetchAvailityClaimStatus = async (queryParams) => {
  const url = AVAILITY_CLAIM_STATUS;

  const request = createGetRequest(url, queryParams);

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));

  return data;
};

export const fetchAvailityClaimStatusPayerConfig = async (ediPayerId) => {
  const url = AVAILITY_CLAIM_STATUS_PAYER_CONFIG;
  const request = createGetRequest(url, {
    edi_payer_id: ediPayerId,
    config: 1,
  });

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));

  return data;
};

export const fetchAvailityClaimStatusSearchPrefillData = async (
  queryParams
) => {
  const url = AVAILITY_CLAIM_STATUS_PREFILL_DATA;
  const request = createGetRequest(url, queryParams);

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => res.data);
  return data;
};

export const fetchClaimStatusViaUHC = async (
  claimControlNumber,
  taxId,
  ediPayerId,
  claimNumber
) => {
  const url = UCH_CLAIM_STATUS;

  const request = createGetRequest(url, {
    claim_control_number: claimControlNumber,
    tax_id: taxId?.replace(/-/g, ''), // Remove hyphens from tax id
    edi_payer_id: ediPayerId,
    claim_number: claimNumber,
  });

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then((res) => camelizeKeys(res.data));

  return data;
};

export const addClaimToStatusTrackerAviality = async (requestPayload) => {
  const request = createRequest(
    AVAILITY_CLAIM_STATUS_SCHEDULE,
    requestPayload,
    {
      baseUrl: BASE_API_URL,
      method: 'POST',
    }
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const addClaimToStatusTracker = async (
  claimNumber,
  taxId,
  ediPayerId,
  schedule,
  numberOfRetries
) => {
  const request = createRequest(
    `${UHC_CLAIM_STATUS_SCHEDULE}`,
    {
      claim_control_number: claimNumber,
      tax_id: taxId?.replace(/-/g, ''), // Remove hyphens from tax id
      edi_payer_id: ediPayerId,
      schedule,
      no_of_retries: numberOfRetries,
    },
    {
      baseUrl: BASE_API_URL,
      method: 'POST',
    }
  );

  return await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchClaimStatusHistory = async ({ offset, sort, filters }) => {
  const nonEmptyParams = pickBy(filters, identity);
  const request = createGetRequest(`${UHC_CLAIM_STATUS_HISTORY}/`, {
    offset,
    sort,
    ...snakeCaseKeys(nonEmptyParams),
  });

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);

  return data;
};

export const fetchClaimStatusHistoryById = async (id) => {
  const request = createGetRequest(`${UHC_CLAIM_STATUS_HISTORY}/${id}`);

  const data = await fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);

  return data;
};
