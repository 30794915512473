/**
 * Created by goer on 11/28/16.
 */

import X2JS from 'x2js';
import React from 'react';
import _ from 'lodash/array';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/pdfjs-express';
import ExpressUtils from '@pdftron/pdfjs-express-utils';

import {
  APIConfig,
  PDFJS_EXPRESS_LICENSE_KEY,
  PDFJS_EXPRESS_UTIL_OPTIONS,
} from 'API/Config';

import { transformToDateInput } from '../../../API/Serializers/Transforms.js';

import {
  validateEmail,
  validatePhoneNumber,
  validateZipCode,
  validateDateString,
  validateNotEmptyString,
} from '../../../helpers/validators';

//
// FIELDNAME_OBLIGATION_INPUTTYPE_<GROUPID|FORMAT>_ERRORNAME
// FIELDNAME        - This name is used for mapping form field to certain data field from Appeal Data structure (or database)
//                    for prefilling functionality.
//                    In case if final field value should consist of few mapped keys use '-' to combine as much field keys
//                    as you need. Final field value will consist of mapped values concatenated with space.
//                    Use FIELDNAME-FIELDNAME template for multiple keys.
//                    For checkboxes following schema should be applied: GROUPNAME-VALUENAME.
// OBLIGATION       - The rule that defines whether field is mandatory or not, its value depends on INPUTTYPE and can be
//                    "OPTIONAL"/"REQUIRED" for text input fields. Also modifier is possible to "OPTIONAL" or "REQUIRED"
//                    which is expected to be field name that should be filled in in order for rule to work. I.e. you
//                    may have text field that should be filled only when certain checkbox set. Then OBLIGATION rule will
//                    be REQUIRED-GROUPNAME-VALUENAME, where GROUPNAME-VALUENAME is the name of checkbox field that's
//                    observed.
//                    For checkboxes this should be number greater or equal to 0 defining minimum amount of checkboxes that
//                    should be selected (for checkbox input type). In case number is set to 1 it's considered as strict
//                    requirement to number of checkboxes set. Basically it's radiobutton mode for group of checkboxes.
// INPUTTYPE        - "TEXT" or "CHECKBOX" so far.
// <GROUPID|FORMAT> - Either group id (just number) that indicates checkboxes within same group so that OBLIGATION rule can
//                    be applied to them or predefined expected format for text input. Possible value for FORMAT:
//                    "DATE", "EMAIL", "PHONENUMBER", "ZIP" or "ANY".
// ERRORNAME        - Human-friendly field/group name that can be referenced in errors. Arbitrary string including spaces and dashes but
//                    not underscores ('_').

const FIELDNAME = 0;
const OBLIGATION = 1;
const INPUTTYPE = 2;
const GROUPID = 3;
const FORMAT = 3;
const ERRORNAME = 4;

export default class PDFForm extends React.Component {
  static propTypes = {
    pdfUrl: PropTypes.string.isRequired,
    prefillMap: PropTypes.array.isRequired,
    shouldPrefill: PropTypes.bool,
    onDocumentLoad: PropTypes.func,
    onDocumentLoadError: PropTypes.func,
    datacy: PropTypes.string,
  };

  static defaultProps = {
    pdfUrl: '',
    prefillMap: [],
    shouldPrefill: true,
  };

  componentWillUnmount() {
    if (
      this.webViewer &&
      this.webViewer.Core &&
      this.webViewer.Core.documentViewer
    ) {
      this.webViewer.Core.documentViewer.removeEventListener(
        'documentLoaded',
        this.onDocumentLoaded
      );
      this.webViewer.Core.documentViewer.removeEventListener(
        'loaderror',
        this.onDocumentLoadError
      );
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.pdfUrl &&
      newProps.pdfUrl.length > 0 &&
      newProps.pdfUrl !== this.props.pdfUrl
    ) {
      const { auth_token: authToken } = APIConfig.processParams();

      this.webViewer.loadDocument(newProps.pdfUrl, {
        customHeaders: {
          authorization: `Token ${authToken}`,
        },
      });
    }
  }

  /**
   * Prefills form fields with data from appeal on document load.
   */
  async onDocumentLoaded() {
    if (!this.webViewer || !this.webViewer.Core) return;

    this.props.onDocumentLoad();
    const { documentViewer, annotationManager } = this.webViewer.Core;

    await documentViewer.getAnnotationsLoadedPromise();

    const fieldManager = annotationManager.getFieldManager();

    fieldManager.forEachField((field) => {
      const fieldName = field.name;
      const fieldValue = field.getValue();

      if (!fieldValue) {
        const nameComponents = getFieldNameComponents(fieldName);

        if (nameComponents[INPUTTYPE] !== 'TEXT') return field;

        const namedFields = getFieldNameSubcomponents(
          nameComponents[FIELDNAME]
        );

        const mappedValues = namedFields.reduce((mappedValues, fieldTag) => {
          const foundValues = this.props.prefillMap.filter(
            (prefilItem) => prefilItem.tag === fieldTag
          );
          if (foundValues.length > 0) {
            let value = foundValues[0].value;
            if (value.length > 1 && isDateField(fieldTag)) {
              // Transform date
              value = transformToDateInput(value);
            }
            mappedValues.push(value);
          }

          return mappedValues;
        }, []);

        field.setValue(mappedValues.join(' '));
      }
    });
  }

  render() {
    return (
      <div className="appeal-letter__pdfcontainer">
        <div className="appeal-letter__pdf" ref={this.onContainerRef} />
      </div>
    );
  }

  /**
   * Returns file for edited PDF.
   * @returns {Blob}
   */
  async getPdfFileData() {
    const { documentViewer, annotationManager } = this.webViewer.Core;
    const utils = new ExpressUtils(PDFJS_EXPRESS_UTIL_OPTIONS);

    const xfdf = await annotationManager.exportAnnotations({});
    const fileData = await documentViewer.getDocument().getFileData({});
    const resp = await utils.setFile(fileData).setXFDF(xfdf).merge();
    const mergedBlob = await resp.getBlob();
    return mergedBlob;
  }

  /**
   * Validates form fields and returns array of errors.
   * @returns {Array}
   */
  async validate() {
    const { annotationManager } = this.webViewer.Core;
    const xfdf = await annotationManager.exportAnnotations({});

    const annotsJSON = this.convertAnnotationsToJSON(xfdf);

    return [...validateTexts(annotsJSON), ...validateCheckboxes(annotsJSON)];
  }

  onContainerRef = (ref) => {
    if (!ref) return;

    const disabledElements = [
      'leftPanelButton',
      'viewControlsButton',
      'viewControlsOverlay',
      'panToolButton',
      'ribbons',
      'toggleNotesButton',
      'menuButton',
      'highlightToolGroupButton',
      'toolsOverlay',
      'eraserToolButton',
      'toolsHeader',
      'selectToolButton',
      'textPopup',
      'contextMenuPopup',
    ];
    WebViewer(
      {
        path: '/lib/webviewer',
        licenseKey: PDFJS_EXPRESS_LICENSE_KEY,
        disabledElements,
      },
      ref
    ).then((instance) => {
      this.webViewer = instance;
      const { auth_token: authToken } = APIConfig.processParams();

      instance.UI.loadDocument(this.props.pdfUrl, {
        customHeaders: {
          authorization: `Token ${authToken}`,
        },
      });

      const { documentViewer } = instance.Core;
      documentViewer.addEventListener('documentLoaded', () =>
        this.onDocumentLoaded()
      );
      documentViewer.addEventListener('loaderror', () =>
        this.onDocumentLoadError()
      );
    });
  };

  onDocumentLoadError = (error) => {
    this.props.onDocumentLoadError(error);
  };

  convertAnnotationsToJSON = (xfdf) => {
    const xfdfParser = new X2JS();
    const annotsJSON = xfdfParser.xml2js(xfdf);

    return annotsJSON;
  };

  convertJSONToAnnotations = (json) => {
    const xfdfParser = new X2JS();
    return xfdfParser.js2xml(json);
  };
}

// VALIDATORS SECTION

const FORMAT_VALIDATORS = {
  DATE: validateDateString,
  EMAIL: validateEmail,
  PHONENUMBER: validatePhoneNumber,
  ZIP: validateZipCode,
  ANY: validateNotEmptyString,
};

const DATE_FIELDS = [
  'PATIENTDOB',
  'SERVICEDATE1',
  'SERVICEDATE2',
  'DOS1',
  'DATEOFINJURY',
];

function validateCheckboxes(annotsJSON) {
  const checkBoxGroups = annotsJSON.xfdf.fields.field.reduce(
    (groups, field) => {
      const nameComponents = getFieldNameComponents(field._name);

      if (nameComponents[INPUTTYPE] !== 'CHECKBOX') return groups;

      for (let i = 0; i < groups.length; i++) {
        const existingGroupId = getFieldNameComponents(groups[i][0]._name)[
          GROUPID
        ];
        if (existingGroupId === nameComponents[GROUPID]) {
          groups[i].push(field);
          return groups;
        }
      }

      groups.push([field]);
      return groups;
    },
    []
  );

  return checkBoxGroups.reduce((errors, checkBoxGroup) => {
    const firstItemNameComponents = getFieldNameComponents(
      checkBoxGroup[0]._name
    );
    const requiredNumberCheckedFields = Number(
      firstItemNameComponents[OBLIGATION]
    );

    const checkedFields = checkBoxGroup.filter((field) => field.value === 'On');
    if (
      requiredNumberCheckedFields === 1 &&
      checkedFields.length !== requiredNumberCheckedFields
    ) {
      errors.push({
        message: `${firstItemNameComponents[ERRORNAME]}: you must choose ${requiredNumberCheckedFields} option`,
        title: firstItemNameComponents[ERRORNAME],
      });
    }
    if (
      requiredNumberCheckedFields !== 1 &&
      checkedFields.length < requiredNumberCheckedFields
    ) {
      errors.push({
        message: `${firstItemNameComponents[ERRORNAME]}: you must choose at least ${requiredNumberCheckedFields} option(s)`,
        title: firstItemNameComponents[ERRORNAME],
      });
    }

    return errors;
  }, []);
}

function validateTexts(annotsJSON) {
  return annotsJSON.xfdf.fields.field.reduce((errors, field) => {
    const nameComponents = getFieldNameComponents(field._name);

    if (nameComponents[INPUTTYPE] !== 'TEXT') return errors;

    const obligations = getFieldNameSubcomponents(nameComponents[OBLIGATION]);
    let obligation = obligations[0];
    if (obligations.length > 1) {
      const dependencyField = findFieldByName(
        annotsJSON,
        _.drop(obligations).join('-')
      );
      if (dependencyField) {
        if (
          obligation === 'OPTIONAL' &&
          validateFieldValueSet(dependencyField)
        ) {
          obligation = 'REQUIRED';
        } else if (
          obligation === 'REQUIRED' &&
          validateFieldValueSet(dependencyField)
        ) {
          obligation = 'OPTIONAL';
        }
      }
    }

    if (obligation === 'OPTIONAL' && validateNotEmptyString(field.value))
      return errors;

    if (FORMAT_VALIDATORS[nameComponents[FORMAT]]) {
      return validateTextField(
        field.value,
        nameComponents[ERRORNAME],
        FORMAT_VALIDATORS[nameComponents[FORMAT]],
        errors
      );
    }

    return errors;
  }, []);
}

function validateTextField(value, name, validator, errors) {
  const error = validator(value);
  if (error) errors.push({ message: `${name}: ${error}`, title: name });
  return errors;
}

function validateCheckboxSet(value) {
  if (value !== 'On') return 'Checkbox should be checked';

  return null;
}

function validateFieldValueSet(field) {
  const nameComponents = getFieldNameComponents(field._name);
  if (nameComponents[INPUTTYPE] === 'TEXT') {
    return validateNotEmptyString(field.value);
  } else if (nameComponents[INPUTTYPE] === 'CHECKBOX') {
    return validateCheckboxSet(field.value);
  }
}

function findFieldByName(annotsJSON, fieldName) {
  const found = annotsJSON.xfdf.fields.field.filter(
    (field) => getFieldNameComponents(field._name)[FIELDNAME] === fieldName
  );
  if (found.length > 0) return found[0];

  return null;
}

function getFieldNameComponents(fieldName) {
  return fieldName.split('_');
}

function getFieldNameSubcomponents(fieldName) {
  return fieldName.split('-');
}

function isDateField(fieldName) {
  return DATE_FIELDS.includes(fieldName);
}
