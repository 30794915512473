import { Serializer as JSONAPISerializer } from 'jsonapi-serializer';
import {
  transformAmountString,
  transformDateString,
  transformNumberToString,
  transformStringWithCommasToArray,
} from './Transforms';

export const EOBProcedureTransform = (object) => {
  // we need to remove dollar signs from amounts, convert quantity to int and date to dashes
  const amountFields = [
    'amount_allowed',
    'billed_amount',
    'payment_amount',
    'deductible',
    'co_insurance',
  ];

  amountFields.forEach((field) => {
    object[field] = object[field] ? transformAmountString(object[field]) : null;
  });

  object.serv_date = object.serv_date
    ? transformDateString(object.serv_date)
    : null;
  object.quantity = object.quantity
    ? transformNumberToString(object.quantity)
    : null;

  object.adj_codes = transformStringWithCommasToArray(object.adj_codes);
  object.remark_codes = transformStringWithCommasToArray(object.remark_codes);
  return object;
};

export const EOBProcedureSerializer = new JSONAPISerializer('EOBProcedure', {
  attributes: [
    'is_selected',
    'cpt_code',
    'cpt_modifiers_code',
    'serv_date',
    'quantity',
    'billed_amount',
    'amount_allowed',
    'amount_allowed_delta',
    'deductible',
    'deductible_delta',
    'adj_codes',
    'co_insurance',
    'co_insurance_delta',
    'payment_amount',
    'remark_codes',
    'payment_amount_delta',
    'EOB',
  ],
  EOB: {
    ref: 'id',
    included: false,
  },
  pluralizeType: false,
  keyForAttribute: 'underscore_case',
});
