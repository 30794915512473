import React from 'react';
import moment from 'moment';
import { debounce } from 'lodash';

import SearchBox from 'components/common/search';
import Dropdown from 'components/Shared/Dropdown';
import { DatePicker } from 'components/common/datepicker';
import CustomDropdown from 'components/common/dropdown/ButtonDropdown/CustomDropdown';

import calendar from 'img/calendar-icon.svg';

import { VALIDATION_DATE_FORMAT } from 'helpers/dateFormats';

const FilterSection = ({
  filters,
  onFilterChange,
  onSearchChange,
  dropdownOptions,
}) => {
  const handleSelectedPracticeChange = (value) =>
    onFilterChange('practice', value);
  const handleSelectedProviderChange = (value) =>
    onFilterChange('providers', value);
  const handleSelectedCptCodeChange = (value) =>
    onFilterChange('procedures', value);
  const handleSelectedProcedureModifierChange = (value) =>
    onFilterChange('modifiers', value);
  const handleSelectedReasonCodeChange = (value) =>
    onFilterChange('reasons', value);
  const handleSelectedRemarkCodeChange = (value) =>
    onFilterChange('remarks', value);

  const debounceOnGeneralSearchFilterChange = (value) => {
    onSearchChange(value);
  };

  const handleDateChange = debounce((name, value) => {
    onFilterChange(name, value);
  }, 300);

  const filterOutAllOptions = (options) =>
    options.filter((item) => item.value !== '');

  return (
    <div className="denied-claims-filter__row">
      <div>
        <div className="">&nbsp;</div>
        <div className="batch-submission-filter__search_string_filter">
          <SearchBox
            placeholder="Search by Name & IDs"
            className="mt-4 mr-8"
            onSearch={debounceOnGeneralSearchFilterChange}
          />
        </div>
      </div>
      <div className="mr-8 width-140">
        <Dropdown
          label="Practice"
          name="practice"
          placeholder="All"
          isClearable={false}
          onChange={handleSelectedPracticeChange}
          value={filters.practice}
          options={dropdownOptions?.practices?.data}
          isLoading={false}
        />
      </div>
      <div className="mr-8 width-140">
        <Dropdown
          label="Provider"
          name="Provider"
          placeholder="All"
          onChange={handleSelectedProviderChange}
          value={filters.providers}
          isClearable={false}
          options={dropdownOptions?.providers?.data}
          isLoading={false}
        />
      </div>
      <div className="mr-8 width-140">
        <CustomDropdown
          label="Procedure"
          options={filterOutAllOptions(dropdownOptions?.cptCodes?.data)}
          isMulti
          onChange={handleSelectedCptCodeChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.procedures,
          }}
        />
      </div>
      <div className="mr-8 width-140">
        <CustomDropdown
          label="Modifier"
          options={filterOutAllOptions(
            dropdownOptions?.procedureModifiers?.data
          )}
          isMulti
          onChange={handleSelectedProcedureModifierChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.modifiers,
          }}
        />
      </div>
      <div className="mr-8 width-140">
        <CustomDropdown
          label="Reason"
          options={filterOutAllOptions(dropdownOptions?.reasonCodes?.data)}
          isMulti
          onChange={handleSelectedReasonCodeChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.reasons,
          }}
        />
      </div>
      <div className="mr-8 width-140">
        <CustomDropdown
          label="Remark"
          options={filterOutAllOptions(dropdownOptions?.remarkCodes?.data)}
          isMulti
          onChange={handleSelectedRemarkCodeChange}
          className="batch-submission-form-dropdown"
          input={{
            value: filters.remarks,
          }}
        />
      </div>
      <div className="mr-8 batch-submission-date-filter single-date-picker__position--left">
        <div className="dropdown__label">Service Date</div>
        <DatePicker
          displayFormat={VALIDATION_DATE_FORMAT}
          customInputIcon={
            <img className="form-icon" src={calendar} alt="Calendar" />
          }
          placeholder="Select Date"
          verticalSpacing={10}
          date={filters.serviceDate ? moment(filters.serviceDate) : null}
          isOutsideRange={() => false}
          onDateChange={(date) => {
            const momentDate = moment(date);
            if (!momentDate.isValid()) {
              handleDateChange('serviceDate', null);
              return;
            }

            handleDateChange(
              'serviceDate',
              momentDate.format(VALIDATION_DATE_FORMAT)
            );
          }}
          orientation="horizontal"
          showClearDate
        />
      </div>
      <div className="batch-submission-date-filter single-date-picker__position--left">
        <div className="dropdown__label">EOB Date</div>
        <DatePicker
          displayFormat={VALIDATION_DATE_FORMAT}
          customInputIcon={
            <img className="form-icon" src={calendar} alt="Calendar" />
          }
          placeholder="Select Date"
          verticalSpacing={10}
          date={filters.eobDate ? moment(filters.eobDate) : null}
          isOutsideRange={() => false}
          onDateChange={(date) => {
            const momentDate = moment(date);
            if (!momentDate.isValid()) {
              handleDateChange('eobDate', null);
              return;
            }
            handleDateChange(
              'eobDate',
              momentDate.format(VALIDATION_DATE_FORMAT)
            );
          }}
          orientation="horizontal"
          showClearDate
        />
      </div>
    </div>
  );
};

export default FilterSection;
