import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Field, formValueSelector } from 'redux-form/immutable';

import LabelValue from '../../Shared/LabelValue';
import { renderReduxMaskedInput } from '../reduxMaskedInput';

import { MASK_DATE } from '../../../helpers/masks';
import { dateIsInThePast } from '../../../helpers/validators';
import { transformDateString } from '../../../API/Serializers/Transforms';
import { getDeadlineForAppeal } from '../../../redux/actions/createAppealActions';

class DenialDateInput extends Component {
  static propTypes = {
    noValuePlaceholder: PropTypes.string,
    loadingPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    shouldCalculateDeadline: PropTypes.bool,
    denialDate: PropTypes.string,
  };

  static defaultProps = {
    noValuePlaceholder: '-',
    loadingPlaceholder: '...',
    disabled: false,
    shouldCalculateDeadline: true,
    denialDate: '',
  };

  render() {
    return (
      <div className="row denial-info-offset-spacing">
        <div className="col-xs-4" datacy="EOB-date-section">
          {this.renderDenialDate()}
        </div>

        <div className="col-xs-4 col-xs-offset-1">
          {this.props.shouldCalculateDeadline && this.renderAppealDeadline()}
        </div>
      </div>
    );
  }

  renderDenialDate() {
    const { disabled } = this.props;
    if (disabled) {
      return (
        <LabelValue label="EOB Date:" value={this.props.deniedAtFormatted} />
      );
    } else {
      return (
        <Field
          label="EOB date:"
          name="denialDate"
          size="10"
          type="string"
          mask={MASK_DATE}
          placeholder="MM/DD/YYYY"
          required={true}
          component={renderReduxMaskedInput}
          datacy="EOB-date-input"
        />
      );
    }
  }

  renderAppealDeadline() {
    const { deadline } = this.props;

    let value;
    if (deadline != null) {
      value = deadline.isLoading
        ? this.props.loadingPlaceholder
        : this.props.deadlineFormatted;
    } else {
      value = this.props.noValuePlaceholder;
    }

    const labelClassName = classNames('labelvalue__value', {
      'labelvalue__value-error': this.props.deadlineIsInvalid,
    });

    return (
      <LabelValue
        label="Appeal Deadline:"
        value={value}
        valueClassName={labelClassName}
      />
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      (!this.props.dateIsSelected && !newProps.dateIsSelected) ||
      (this.props.dateIsSelected === newProps.dateIsSelected &&
        this.props.denialDate === newProps.denialDate)
    ) {
      return;
    }

    const { denialDate } = newProps;

    if (
      newProps.dateIsSelected &&
      this.props.selectedDate !== denialDate &&
      _isValidDateInput(denialDate, true)
    ) {
      this.downloadDeadline(denialDate);
    }
  }

  downloadDeadline = (denialDate) => {
    if (this.props.shouldCalculateDeadline === true) {
      this.props.actions.getDeadlineForAppeal(
        transformDateString(denialDate),
        this.props.appealID
      );
    }
  };
}

const selector = formValueSelector('denial_info');

function mapStateToProps(state, ownProps) {
  let denialDate = null;
  const selectedDenialDate = selector(state, 'denialDate');
  let initialDeadline = null;
  const stateDeadline = selector(state, 'deadline');

  if (selectedDenialDate && _isValidDateInput(selectedDenialDate, true)) {
    denialDate = transformDateString(selectedDenialDate);
  }
  if (stateDeadline && _isValidDateInput(stateDeadline, true)) {
    initialDeadline = transformDateString(stateDeadline);
  }

  const dateIsSelected = !!denialDate;

  const dateProps = { denialDate, dateIsSelected };

  const deadlineStore = state.get('deadlineStore');

  if (initialDeadline) {
    dateProps.deadline = initialDeadline;
    dateProps.deadlineFormatted = moment(initialDeadline).format('LL');
  }

  if (!deadlineStore.has(ownProps.appealID) || !dateIsSelected) {
    return dateProps;
  }

  const deadline = deadlineStore.get(ownProps.appealID).toJS();
  const deadlineFormatted = moment(deadline.deadlineDate).format('LL');

  let deadlineIsInvalid = false;
  if (deadline && deadline.deadlineDate) {
    if (deadline.deadlineDate < moment().format('YYYY-MM-DD')) {
      deadlineIsInvalid = true;
    }
  }

  let deniedAtFormatted = null;
  if (ownProps.disabled) {
    deniedAtFormatted = moment(denialDate).format('LL');
  }

  return {
    ...dateProps,
    deadline,
    deadlineFormatted,
    deniedAtFormatted,
    deadlineIsInvalid,
  };
}

function _isValidDateInput(dateStr, allowPast = false) {
  return (
    dateStr &&
    dateStr.length === 10 &&
    !dateStr.includes('_') &&
    (allowPast || dateIsInThePast(dateStr))
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getDeadlineForAppeal }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DenialDateInput);
