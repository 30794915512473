import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import React, { useRef, useState } from 'react';

import Button from 'components/common/button';
import * as toast from 'components/Shared/toast';

import './style.css';

const MAX_FILE_SIZE = 2 * 1024 * 1024;

const FileDropzone = ({
  attachments = [],
  onAttachmentsChange,
  lettersAndFormsCount,
  getPageValidationError,
}) => {
  const { letterCount, formCount } = lettersAndFormsCount;

  const dropzoneRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const acceptedTypes = ['.pdf', '.png', '.jpg'];

  const onDrop = async (acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      const fileType = `.${file.name.split('.').pop().toLowerCase()}`;
      if (!acceptedTypes.includes(fileType)) {
        toast.error({
          title: 'Invalid File Type',
          message: 'Only PNG, JPG, and PDF files are accepted.',
        });
        return false;
      }

      if (file.size === 0) {
        toast.error({
          title: 'Empty File',
          message: `File content empty. Please upload valid file. Filename: ${file.name}.`,
        });
        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        toast.error({
          title: 'Invalid File Size',
          message: `${file.name} is too large. Maximum file size allowed is 2MB`,
        });
        return false;
      }

      return true;
    });
    const totalLetterAndFormCount = letterCount + formCount;
    const pageValidationError = await getPageValidationError(
      validFiles,
      attachments,
      totalLetterAndFormCount
    );
    if (pageValidationError) {
      return toast.error({
        title: 'Error',
        message: pageValidationError,
      });
    }
    onAttachmentsChange([...attachments, ...validFiles]);
  };

  const onSelectFileClicked = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const renderAttachmentInfo = () => {
    const buttonClassName = 'batch-submission-file-dropzone__button';
    const buttonContainerClassName =
      'batch-submission-file-dropzone__button-container';
    if (attachments.length === 0) {
      return (
        <div className={buttonContainerClassName}>
          <Button
            title="+ Add Attachments"
            onClick={onSelectFileClicked}
            className={`${buttonClassName} batch-submission-file-dropzone__button--lg`}
          />
        </div>
      );
    } else if (attachments.length === 1) {
      return (
        <div className={buttonContainerClassName}>
          <span className="file-name width-150">{attachments[0].name}</span>
          <Button
            title="+"
            onClick={onSelectFileClicked}
            className={`${buttonClassName} batch-submission-file-dropzone__button--sm`}
          />
        </div>
      );
    } else {
      return (
        <div className={buttonContainerClassName}>
          <span className="width-150">{attachments.length} Documents</span>
          <Button
            title="+"
            onClick={onSelectFileClicked}
            className={`${buttonClassName} batch-submission-file-dropzone__button--sm`}
          />
        </div>
      );
    }
  };

  return (
    <Dropzone
      ref={dropzoneRef}
      onDrop={onDrop}
      multiple={true}
      noClick={true}
      className={classnames('batch-submission-file-dropzone', {
        'batch-submission-file-dropzone--drag-over': isDragOver,
      })}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragOver(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setIsDragOver(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setIsDragOver(false);
        }}
      >
        {renderAttachmentInfo()}
      </div>
    </Dropzone>
  );
};

export default FileDropzone;
