import PropTypes from 'prop-types';
import { omit, isEqual } from 'lodash';
import React, { useState } from 'react';

import Button from 'components/common/button';
import Dropdown from 'components/Shared/Dropdown';

import { BUTTON_TYPE } from 'components/common/button/Button';
import ScreenDimmerComponent from 'components/Shared/ScreenDimmerComponent';

import closeIcon from 'img/close-modal.svg';
import settingsIcon from 'img/settings-black.svg';
import ToggleSwitch from 'components/common/toggle';
import CreatableSelect from 'react-select/creatable';

const InProgressSubmissionPopupFilter = (props) => {
  const {
    setShowPopupFilter,
    filters,
    onFilterChange,
    options,
    defaultFilters,
    getSelectedValue,
    userPractices,
    loading,
  } = props;
  const [inProgressFilters, setInProgressFilters] = useState(filters);

  const keysToOmit = ['search', 'submissionType'];
  const isFilterEmpty = Object.keys(omit(inProgressFilters, keysToOmit)).every(
    (filterKey) => filters[filterKey] === defaultFilters[filterKey]
  );

  const filtersWithoutOmittedKeys = (filters) => omit(filters, keysToOmit);
  const inProgressFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(filters);
  const inProgressPopupFiltersWithoutOmittedKeys =
    filtersWithoutOmittedKeys(inProgressFilters);
  const isApplyFilterDisabled = isEqual(
    Object.values(inProgressFiltersWithoutOmittedKeys),
    Object.values(inProgressPopupFiltersWithoutOmittedKeys)
  );

  const resetFilters = () => onFilterChange(omit(defaultFilters, keysToOmit));

  const handleConfirmFilters = () => {
    onFilterChange(inProgressFilters);
    setShowPopupFilter(false);
  };

  const handleFilterChange = (key, value) => {
    setInProgressFilters({ ...inProgressFilters, [key]: value });
  };

  const onChangeBatchIds = (selectedBatchId) => {
    const batchIdList = selectedBatchId.map((batchId) => ({
      ...batchId,
      label: batchId.label.toLowerCase(),
      value: batchId.value.toLowerCase(),
    }));
    setInProgressFilters({ ...inProgressFilters, batchId: batchIdList });
  };

  return (
    <ScreenDimmerComponent>
      <div className="appealio-popup appealio-popup-filter">
        <div className="appealio-popup-filter__header">
          <div className="filter__header-title">
            <img src={settingsIcon} alt="More filters" />
            <h6>More Filters </h6>
          </div>
          <div className="appealio-popup-filter__clear-wrapper">
            <div className="filter__header--reset-option">
              {!isFilterEmpty && (
                <p
                  className="denial-table__filter-clear text-decoration-underline"
                  onClick={() => {
                    setInProgressFilters(defaultFilters);
                    resetFilters();
                    setShowPopupFilter(false);
                  }}
                >
                  Reset All
                </p>
              )}
            </div>
            <img
              alt="Close"
              onClick={() => setShowPopupFilter(false)}
              className="close-icon"
              src={closeIcon}
            />
          </div>
        </div>
        <div className="appealio-popup-filter__options">
          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                options={options.payers}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('payerId', option.value)
                }
                isLoading={loading}
                value={getSelectedValue(
                  options.payers,
                  Number(inProgressFilters.payerId)
                )}
                label="Payer"
              />
            </div>
            <div className="col-md-6">
              <Dropdown
                options={options.providers}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('providerId', option.value)
                }
                isLoading={loading}
                label="Provider"
                value={getSelectedValue(
                  options.providers,
                  inProgressFilters.providerId
                )}
              />
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-md-6">
              <Dropdown
                options={options.agents}
                placeholder="All"
                onChange={(option) =>
                  handleFilterChange('agentId', option.value)
                }
                isLoading={loading}
                label="Agent"
                value={getSelectedValue(
                  options.agents,
                  inProgressFilters.agentId
                )}
              />
            </div>
            <div className="col-md-6">
              {userPractices && userPractices.length > 1 && (
                <Dropdown
                  options={options.practices}
                  placeholder="All"
                  onChange={(option) =>
                    handleFilterChange('practiceIdentifier', option.value)
                  }
                  isLoading={loading}
                  label="Practice"
                  value={getSelectedValue(
                    options.practices,
                    inProgressFilters.practiceIdentifier
                  )}
                />
              )}
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-md-6">
              <label className="dropdown__label">Batch #</label>
              <CreatableSelect
                onChange={onChangeBatchIds}
                value={inProgressFilters?.batchId || []}
                isMulti
              />
            </div>
            <div className="col-md-6">
              <ToggleSwitch
                className="mt-28"
                optionClassName="fs-12"
                options={{
                  option1: 'All',
                  option2: 'In Progress (More than 3 days)',
                }}
                checked={inProgressFilters.notificationRedirect || false}
                handleToggle={(e) =>
                  handleFilterChange('notificationRedirect', e.target.checked)
                }
              />
            </div>
          </div>
        </div>
        <div className="appealio-popup-filter-buttons mt-24">
          <Button
            title="Apply"
            type={BUTTON_TYPE.SECONDARY}
            className="ap-button ap-button--block"
            onClick={handleConfirmFilters}
            datacy="dataCy-confirm-filters"
            disabled={isApplyFilterDisabled}
          />
        </div>
      </div>
    </ScreenDimmerComponent>
  );
};

InProgressSubmissionPopupFilter.propTypes = {
  setShowPopupFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  getSelectedValue: PropTypes.func,
};

export default InProgressSubmissionPopupFilter;
