import { Deserializer as JSONAPIDeserializer } from 'jsonapi-serializer';

import {
  EOBProcedureSerializer,
  EOBProcedureTransform,
} from './Serializers/EOB';
import {
  AppealSerializer,
  AppealSerializerTransform,
} from './Serializers/AppealSerializer';
import {
  AppealDirectSubmitSerializer,
  AppealEmailSubmitSerializer,
  AppealFaxSubmitSerializer,
  AppealMailSubmitSerializer,
} from './Serializers/AppealSubmissionSerializers';
import { DocumentSerializer } from './Serializers/DocumentSerializer';
import {
  PatientInfoSerializer,
  PatientInfoSerializerTransform,
} from './Serializers/PatientInfoSerializer';
import { AppealInfoSerializer } from './Serializers/AppealInfoSerializer';
import { AppealLetterSerializer } from './Serializers/AppealLetterSerializer';
import { AppealNotesSerializer } from './Serializers/AppealNotesSerializer';
import { HelpSerializer } from './Serializers/HelpSerializer';

import AppealNotesAPI from './AppealNotesAPI';
import AppealSubmitLogsAPI from './AppealSubmitLogsAPI';

import { DOCUMENT_CATEGORY } from 'constants/appConstants';

import {
  transformToDateInput,
  transformArrayToStringWithCommas,
} from './Serializers/Transforms';

import _ from 'lodash';
import URI from 'urijs';

export const OUR_NAMING_CONVENTION = 'camelCase';

export const createDeserializerOptions = (
  relationshipsMapper = {},
  keyForAttribute = OUR_NAMING_CONVENTION
) => {
  return {
    ...relationshipsMapper,
    keyForAttribute,
  };
};

export const createDeserializer = (relationshipsMapper, keyForAttribute) => {
  return new JSONAPIDeserializer(
    createDeserializerOptions(relationshipsMapper, keyForAttribute)
  );
};

/*
It reads 'id' property from  relationship like that
"relationships": {
    "provider": {
        "data": {
            "type": "Provider",
            "id": "10"
        }
    }
  }

and converts it to object's property:
{
 /... attributes
 provider: {
    id: "10"
  }
}
*/
export const idRelationshipExtractor = (relationshipName) => {
  return {
    [relationshipName]: {
      valueForRelationship: (relationship) => {
        return {
          id: relationship.id,
        };
      },
    },
  };
};

// DESERIALIZE
export const parseAppeal = (jsonAPIResponse) => {
  return createDeserializer(idRelationshipExtractor('EOB')).deserialize(
    jsonAPIResponse
  );
};

export const parseEOBProcedure = (eobProcedure) => {
  let objectToDeserialize;
  if (!eobProcedure.data) {
    objectToDeserialize = {
      data: eobProcedure,
    };
  } else {
    objectToDeserialize = eobProcedure;
  }

  return createDeserializer({}, 'underscore_case')
    .deserialize(objectToDeserialize)
    .then((parsedProcedure) => {
      parsedProcedure.serv_date = parsedProcedure.serv_date
        ? transformToDateInput(parsedProcedure.serv_date)
        : null;
      parsedProcedure.adj_codes = transformArrayToStringWithCommas(
        parsedProcedure.adj_codes
      );
      parsedProcedure.remark_codes = transformArrayToStringWithCommas(
        parsedProcedure.remark_codes
      );

      return parsedProcedure;
    });
};

export const parseUser = (jsonAPIResponse) => {
  return createDeserializer(jsonAPIResponse).deserialize(jsonAPIResponse);
};

export const parsePatient = (jsonAPIResponse) => {
  return createDeserializer(idRelationshipExtractor('Provider')).deserialize(
    jsonAPIResponse
  );
};

export const removeIDFromSerialization = (serialization) => {
  serialization.data = _.omit(serialization.data, 'id');
  return serialization;
};
// TL;DR: removes id: "undefined"
// when original object didn't have id property
// serialization lib adds id: "undefined" to serialized object
// so we remove it after serialization process
export const postTransformSerialization = (serialization) => {
  if (serialization.data.id === 'undefined') {
    return removeIDFromSerialization(serialization);
  } else {
    return serialization;
  }
};

export const parseAppealFullDetails = (jsonAPIResponse) => {
  const notesInRelationship = _.defaults(
    jsonAPIResponse.data.relationships.notes,
    { data: [] }
  );
  const idsOfNotes = notesInRelationship.data.map((note) => note.id);
  const notesIncluded = _.filter(jsonAPIResponse.included, (el) => {
    return el.type === 'AppealNote' && _.includes(idsOfNotes, el.id);
  });

  const parsedNotes = notesIncluded.map((note) => {
    return AppealNotesAPI.parseNote(note);
  });

  const eobProceduresArray = _.filter(
    jsonAPIResponse.included,
    (el) => el.type === 'EOBProcedure'
  );
  const eobProceduresDeserializePromises = eobProceduresArray.map(
    (eobProcedure) => {
      return parseEOBProcedure(eobProcedure);
    }
  );

  const lettersArray = _.filter(
    jsonAPIResponse.included,
    (el) => el.type === 'AppealLetter'
  );
  const lettersDeserializePromises = lettersArray.map((letter) => {
    return createDeserializer({}, 'underscore_case').deserialize({
      data: letter,
    });
  });

  const documentsArray = _.filter(
    jsonAPIResponse.included,
    (el) => el.type === 'AppealDocument'
  );
  const documentsDeserializePromises = documentsArray.map((document) => {
    return createDeserializer().deserialize({
      data: document,
    });
  });

  const payerContactInRelationship = _.defaults(
    jsonAPIResponse.data.relationships.payer_contact,
    { data: {} }
  );
  const payerContactIncluded = _.filter(jsonAPIResponse.included, (el) => {
    return (
      el.type === 'PayerContact' && el.id === payerContactInRelationship.data.id
    );
  });

  const payerContactDeserializePromise = !_.isEmpty(payerContactIncluded)
    ? createDeserializer().deserialize({
        data: _.head(payerContactIncluded),
      })
    : new Promise((resolve) => {
        resolve(null);
      });

  let agentDeserializer = null;
  const agentsInRelationship = _.defaults(
    jsonAPIResponse.data.relationships.agent,
    { data: {} }
  );
  const agentsData = _.filter(
    jsonAPIResponse.included,
    (el) =>
      el.type === 'User' &&
      !_.isEmpty(agentsInRelationship.data) &&
      el.id === agentsInRelationship.data.id
  );
  if (!_.isEmpty(agentsData)) {
    agentDeserializer = parseUser({
      data: _.head(agentsData),
    });
  }

  let clinicDeserializer = null;
  const clinicsInRelationship = _.defaults(
    jsonAPIResponse.data.relationships.clinic,
    { data: {} }
  );
  const clinicsData = _.filter(
    jsonAPIResponse.included,
    (el) =>
      el.type === 'Clinic' &&
      !_.isEmpty(clinicsInRelationship.data) &&
      el.id === clinicsInRelationship.data.id
  );
  if (!_.isEmpty(clinicsData)) {
    clinicDeserializer = createDeserializer().deserialize({
      data: _.head(clinicsData),
    });
  }

  let authorDeserializer = null;
  const authorsInRelationship = _.defaults(
    jsonAPIResponse.data.relationships.author,
    { data: {} }
  );
  const authorsData = _.filter(
    jsonAPIResponse.included,
    (el) =>
      el.type === 'User' &&
      !_.isEmpty(authorsInRelationship.data) &&
      el.id === authorsInRelationship.data.id
  );
  if (!_.isEmpty(authorsData)) {
    authorDeserializer = parseUser({
      data: _.head(authorsData),
    });
  }

  const patientsData = _.filter(
    jsonAPIResponse.included,
    (el) => el.type === 'Patient'
  );
  const patientDeserializer = parsePatient({
    data: _.head(patientsData),
  });

  // due deserializer library bug "included" part causes freeze crash, so I am parsing included EOBProcedures separately
  const noIncluded = _.pick(jsonAPIResponse, ['data']);

  const eobID = idRelationshipExtractor('EOB');
  const payerContactID = idRelationshipExtractor('PayerContact');

  const relationshipMapper = {
    ...eobID,
    ...payerContactID,
  };
  const eobDeserializer =
    createDeserializer(relationshipMapper).deserialize(noIncluded);

  const submitLogsInRelationship = _.defaults(
    jsonAPIResponse.data.relationships.submit_logs,
    { data: [] }
  );
  const idsOfLogs = submitLogsInRelationship.data.map((log) => log.id);
  const submitLogsIncluded = _.filter(jsonAPIResponse.included, (el) => {
    return el.type === 'AppealSubmitLog' && _.includes(idsOfLogs, el.id);
  });

  const submitLogsResolved =
    AppealSubmitLogsAPI.parseSubmitLogsData(submitLogsIncluded);

  return Promise.all([
    eobDeserializer,
    patientDeserializer,
    authorDeserializer,
    agentDeserializer,
    clinicDeserializer,
  ]).then(([appeal, patientDone, author, agent, clinic]) => {
    const eobProceduresResolved = Promise.all(
      eobProceduresDeserializePromises
    ).then((eobProcedures) => eobProcedures);
    const lettersResolved = Promise.all(lettersDeserializePromises).then(
      (letters) => letters
    );
    const documentsResolved = Promise.all(documentsDeserializePromises).then(
      (documents) => documents
    );
    const payerContactResolved = payerContactDeserializePromise.then(
      (payer) => payer
    );

    return Promise.all([
      eobProceduresResolved,
      lettersResolved,
      documentsResolved,
      payerContactResolved,
      submitLogsResolved,
    ]).then(([eobProcedures, letters, documents, payerContact, submitLogs]) => {
      appeal.agent = agent;

      appeal.author = author;

      appeal.patient = patientDone;

      appeal.letters = _.orderBy(letters, (l) => l.order);

      appeal.documents = documents;

      appeal.payerContact = payerContact;

      appeal.eob = {
        ...appeal.eob,
        procedures: eobProcedures,
      };

      appeal.notes = parsedNotes;

      appeal.submitLogs = submitLogs;

      appeal.clinic = clinic;

      return appeal;
    });
  });
};

export const getIncludedAppeals = (jsonAPIResponse, propertyName) => {
  const relationships = _.defaults(
    jsonAPIResponse.data.relationships[propertyName],
    { data: [] }
  );
  const idsOfAppeals = relationships.data.map((appeal) => appeal.id);
  const includedAppeals = _.filter(jsonAPIResponse.included, (el) => {
    // Make sure we are only parsing nested_appeals which
    // are mentioned in relationships.nested_appeals.
    // There might be a case when there's Appeal in jsonAPIResponse.included
    // but it doesn't belong to root appeal.
    return el.type === 'Appeal' && _.includes(idsOfAppeals, el.id);
  });

  if (_.isEmpty(includedAppeals)) {
    // let's return pure ids then
    return relationships.data.map((appeal) => {
      return {
        id: appeal.id,
        attributes: {},
      };
    });
  }
  return _.orderBy(includedAppeals, 'attributes.appeal_round', 'desc');
};

export const parseAppealWithNestedAppeals = (jsonAPIResponse) => {
  const rootAppealParse = parseAppealFullDetails(jsonAPIResponse);

  const nestedAppealsArray = getIncludedAppeals(
    jsonAPIResponse,
    'nested_appeals'
  );
  const historyAppealsArray = getIncludedAppeals(
    jsonAPIResponse,
    'history_appeals'
  );

  const nestedAppealsDeserializePromises = nestedAppealsArray.map(
    (nestedAppeal) => {
      return parseAppeal({ data: nestedAppeal });
    }
  );

  const historyAppealsDeserializePromises = historyAppealsArray.map(
    (historyAppeal) => {
      return parseAppeal({ data: historyAppeal });
    }
  );

  return rootAppealParse.then((rootAppeal) => {
    return Promise.all(nestedAppealsDeserializePromises).then(
      (nestedAppeals) => {
        return Promise.all(historyAppealsDeserializePromises).then(
          (historyAppeals) => {
            return {
              ...rootAppeal,
              nestedAppeals,
              historyAppeals,
            };
          }
        );
      }
    );
  });
};

export const parseAppealLetter = (jsonAPIResponse) => {
  return createDeserializer(
    idRelationshipExtractor('AppealLetter')
  ).deserialize(jsonAPIResponse);
};

export const parseUserInfo = (jsonAPIResponse) => {
  return createDeserializer().deserialize(jsonAPIResponse);
};

// SERIALIZE
export const serializeEOBProcedure = (eobProcedure, eobID) => {
  const mergedObject = {
    ...eobProcedure,
    EOB: { id: eobID, type: 'EOB' },
  };

  return postTransformSerialization(
    EOBProcedureSerializer.serialize(EOBProcedureTransform(mergedObject))
  );
};

export const serializeAppeal = (appealID, attrs) => {
  return AppealSerializer.serialize(
    AppealSerializerTransform({ ...attrs, id: appealID })
  );
};

export const serializePatientInfo = (
  patientInfo,
  appealID,
  addAppealId = false
) => {
  const mergedObject = {
    ...patientInfo,
    // Appeals: [{id: appealID, type: 'Appeal'}], // Commented out to handle update patient API crash
    Provider: { id: patientInfo.provider, type: 'Provider' },
  };

  if (addAppealId) {
    mergedObject.Appeals = [{ id: appealID, type: 'Appeal' }];
  }

  return postTransformSerialization(
    PatientInfoSerializer.serialize(
      PatientInfoSerializerTransform(mergedObject)
    )
  );
};

export const serializeAppealInfo = (appealInfo) => {
  const mergedObject = {
    ...appealInfo,
  };

  if (appealInfo.payer) {
    mergedObject.Payer = appealInfo.payer;
    delete mergedObject.payer;
  }

  if (appealInfo.payerContact) {
    mergedObject.PayerContact = {
      id: appealInfo.payerContact,
      type: 'PayerContact',
    };
  }

  return postTransformSerialization(
    AppealInfoSerializer.serialize(mergedObject)
  );
};

export const serializeAppealAgent = (appealInfo) => {
  const mergedObject = {
    ...appealInfo,
    Agent: { id: appealInfo.agent, type: 'Agent' },
  };

  return postTransformSerialization(
    AppealInfoSerializer.serialize(mergedObject)
  );
};

export const serializeAppealNotes = (appealNote) => {
  const noteObject = {
    ...appealNote,
    Appeal: { id: appealNote.id, type: 'Appeal' },
  };

  return postTransformSerialization(
    AppealNotesSerializer.serialize(noteObject)
  );
};

export const serializeAppealLetter = (appealLetter) => {
  let appealWithHtml = {
    html: appealLetter.content.html,
    ...appealLetter,
  };
  // Since appealLetter contains input data that might be used
  // for different types of requests we need to change its structure to match
  // expected serialized schema (which should not have `content` field inside)
  appealWithHtml = _.omit(appealWithHtml, 'content');

  const mergedObject = {
    ...appealWithHtml,
    Appeal: { id: appealLetter.appealId, type: 'Appeal' },
  };

  if (appealLetter.templateId)
    mergedObject.Template = {
      id: appealLetter.templateId,
      type: 'AppealTemplate',
    };

  return postTransformSerialization(
    AppealLetterSerializer.serialize(mergedObject)
  );
};

export const serializeAppealLetterWithoutContent = (appealLetter) => {
  const mergedObject = {
    ...appealLetter,
    Appeal: { id: appealLetter.appealId, type: 'Appeal' },
  };

  if (appealLetter.templateId)
    mergedObject.Template = {
      id: appealLetter.templateId,
      type: 'AppealTemplate',
    };

  return postTransformSerialization(
    AppealLetterSerializer.serialize(mergedObject)
  );
};

export const serializeAppealLetterForm = (appealLetter) => {
  const formData = new FormData();
  formData.append('name', appealLetter.name);
  formData.append('pdf_file', appealLetter.content.blob);
  if (appealLetter.templateId) {
    // optional parameter according to API spec
    formData.append('template_id', appealLetter.templateId);
  }

  return formData;
};

export const serializeAppealSubmitFax = (attrs) => {
  const mergedObject = {
    ...attrs,
    Appeal: { id: attrs.appealID, type: 'Appeal' },
  };

  return postTransformSerialization(
    AppealFaxSubmitSerializer.serialize(mergedObject)
  );
};

export const serializeAppealSubmitDirect = (attrs) => {
  const mergedObject = {
    ...attrs,
    Appeal: { id: attrs.appealID, type: 'Appeal' },
  };

  return postTransformSerialization(
    AppealDirectSubmitSerializer.serialize(mergedObject)
  );
};

export const serializeAppealSubmitMail = (attrs) => {
  const mergedObject = {
    ...attrs,
    Appeal: { id: attrs.appealID, type: 'Appeal' },
  };

  return postTransformSerialization(
    AppealMailSubmitSerializer.serialize(mergedObject)
  );
};

export const serializeAppealSubmitEmail = (attrs) => {
  const mergedObject = {
    ...attrs,
    Appeal: { id: attrs.appealID, type: 'Appeal' },
  };

  return postTransformSerialization(
    AppealEmailSubmitSerializer.serialize(mergedObject)
  );
};

export const serializeHelpInfo = (attrs) => {
  return postTransformSerialization(HelpSerializer.serialize(attrs));
};

export const serializeDocument = (submissionId, file) => {
  const filePath = new URI(file.signedUrl)
    .pathname(true)
    .replace('/attachments/', '');

  const mergedObject = {
    linkPath: filePath,
    docType: file.type,
    preview: file.preview,
    rank: file.rank,
    submission_id: submissionId,
    documentCategory:
      file?.documentCategory || DOCUMENT_CATEGORY.APPEAL_ATTACHMENT,
  };
  return postTransformSerialization(DocumentSerializer.serialize(mergedObject));
};

export const serializePortalInfo = (data) => {
  return [
    { label: 'Claim Number', value: data.claimNumber },
    { label: 'Service From Date', value: data.serviceDateFrom },
    { label: 'Service To Date', value: data.serviceDateTo },
    { label: 'Member ID', value: data.memberId },
    { label: 'Patient Last Name', value: data.patient.lastName },
    { label: 'Patient First Name', value: data.patient.firstName },
    { label: 'Date of Birth', value: data.patient.dob },
    { label: 'Rendering Provider', value: data.providerName },
    { label: 'Rendering Provider NPI', value: data.renderingProviderNpi },
    { label: 'Billing Provider', value: data.billingProviderName },
    { label: 'Billing Provider Tax ID', value: data.billingProviderTaxId },
    { label: 'Billing Provider NPI', value: data.billingProviderNpi },
    { label: 'Group Number', value: data.groupNumber },
  ];
};
