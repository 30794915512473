import moment from 'moment';
import debounce from 'lodash/debounce';
import { useState, useEffect, useCallback, useMemo } from 'react';

import {
  fetchDenialsAndClaims,
  getSubmissionData,
} from 'API/BatchSubmissionAPI';
import CreateAppealAPI from 'API/CreateAppealAPI';

import { handleError } from 'helpers/errorHandler';
import { formatTemplate } from 'components/CreateAppeal/AppealLetter/TemplateFiller';

import { DATE_FORMAT_YYYY_MM_DD } from 'helpers/dateFormats';

/**
 * Custom hook to fetch and manage claims data
 * @param {Object} filters - The filters to apply to the claims data
 * @param {string} searchTerm - The search term to filter claims
 * @param {number} currentPage - The current page number
 * @param {boolean} shouldFetchData - Whether data should be fetched
 * @returns {Object} An object containing claims data, loading state, error state, and a refetch function
 */
export const useClaimsData = (
  filters,
  searchTerm,
  currentPage,
  shouldFetchData
) => {
  const [claimsData, setClaimsData] = useState({
    rows: [],
    total: 0,
    page: 1,
    pages: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const processFilters = useCallback((filters) => {
    const params = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        switch (key) {
          case 'practice':
            params.practice_identifier = value.value;
            break;
          case 'payer':
            params.payer_master_id = value.value;
            break;
          case 'providers':
            params.provider_master_id = value.value.split('-')[1];
            break;
          case 'procedures':
            params.cpt_code = getArrayValues(value);
            break;
          case 'modifiers':
            params.cpt_modifiers = getArrayValues(value);
            break;
          case 'reasons':
            params.reason_codes = getArrayValues(value);
            break;
          case 'remarks':
            params.remark_codes = getArrayValues(value);
            break;
          case 'eobDate':
            params.eob_date = moment(value)?.format(DATE_FORMAT_YYYY_MM_DD);
            break;
          case 'serviceDate':
            params.service_date = moment(value)?.format(DATE_FORMAT_YYYY_MM_DD);
            break;
          default:
            break;
        }
      }
    });
    return params;
  }, []);

  const fetchClaims = useCallback(async () => {
    if (!shouldFetchData) return;
    setIsLoading(true);
    setError(null);
    try {
      const params = {
        page: currentPage,
        ...processFilters(filters),
      };

      if (searchTerm) {
        params.search = searchTerm;
      }

      const response = await fetchDenialsAndClaims(params);
      const { data, meta } = response;

      setClaimsData({
        rows: data.map((claim) => ({
          claimNumber: claim.claimNumber,
          claimControlNumber: claim.claimControlNumber,
          patientName: claim.patientName,
          providerName: claim.providerName,
          practiceName: claim.practiceName,
          cptCodes: claim.cptCodes,
          cptModifiersCodes: claim.cptModifiers,
          reasonCodes: claim.reasonCodes,
          remarkCodes: claim.remarkCodes,
          billedAmount: claim.billedAmount,
          paymentAmount: claim.paymentAmount,
          serviceDates: claim?.serviceDates,
          manualClaimId: claim.manualClaimId,
          eobDate: claim.eobDate,
        })),
        total: meta.total,
        page: meta.page,
        pages: meta.pages,
      });
    } catch (err) {
      setError('Failed to fetch claims data');
    } finally {
      setIsLoading(false);
    }
  }, [shouldFetchData, currentPage, filters, searchTerm, processFilters]);

  const debouncedFetchClaims = useMemo(
    () => debounce(fetchClaims, 300),
    [fetchClaims]
  );

  useEffect(() => {
    debouncedFetchClaims();
    return () => debouncedFetchClaims.cancel();
  }, [debouncedFetchClaims]);

  return { claimsData, isLoading, error, refetch: fetchClaims };
};

/**
 * Custom hook to fetch and manage appeal data
 * @param {boolean} isWidgetOpen - Whether the widget is open
 * @param {number} appealIdToDuplicate - The ID of the appeal to duplicate
 * @returns {Object} An object containing the fetching state, selected appeal data, and any error
 */
export const useAppealData = (isWidgetOpen, appealIdToDuplicate) => {
  const [isFetchingAppeal, setIsFetchingAppeal] = useState(false);
  const [selectedAppeal, setSelectedAppeal] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (!isWidgetOpen || !appealIdToDuplicate) {
        if (selectedAppeal) {
          setSelectedAppeal(null);
        }
        return;
      }
      try {
        setIsFetchingAppeal(true);
        const appeal = await getSubmissionData(appealIdToDuplicate);

        setSelectedAppeal(appeal);
        setError(null);
      } catch (error) {
        setError(error);
        handleError(error);
      } finally {
        setIsFetchingAppeal(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidgetOpen, appealIdToDuplicate]);

  return { isFetchingAppeal, selectedAppeal, error };
};

/**
 * Custom hook to fetch and manage template options for appeals
 * @param {number} appealId - The ID of the appeal
 * @param {number} payerId - The ID of the payer
 * @returns {Object} An object containing template options, loading state, and any error
 */
export const useTemplateOptions = (appealId, payerId) => {
  const [templateOptions, setTemplateOptions] = useState([]);
  const [appealLetterTemplateOptions, setAppealLetterTemplateOptions] =
    useState([]);
  const [appealFormTemplateOptions, setAppealFormTemplateOptions] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTemplateOptions = async () => {
      if (appealId && payerId) {
        setIsLoading(true);
        setError(null);
        try {
          const data = await CreateAppealAPI.getDropdownOptions(
            'appeal_templates',
            {
              payer_id: payerId,
              appeal_id: appealId,
            }
          );
          const parsedTemplates = CreateAppealAPI.parseTemplates(data);
          setTemplateOptions(parsedTemplates);

          const letterOptions = parsedTemplates
            .filter(({ type }) => type === 'html')
            .map(({ id, title, content }) => ({
              value: id,
              label: title,
              content: formatTemplate(content, [{ tag: 'DUMMY' }]),
              type: 'html',
            }));
          setAppealLetterTemplateOptions(letterOptions);

          const formOptions = parsedTemplates
            .filter(({ type }) => type === 'pdf')
            .map(({ id, title }) => ({ value: id, label: title, type: 'pdf' }));
          setAppealFormTemplateOptions(formOptions);
        } catch (err) {
          setError(err.message || 'Failed to fetch template options');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTemplateOptions();
  }, [appealId, payerId]);

  return {
    templateOptions,
    appealLetterTemplateOptions,
    appealFormTemplateOptions,
    isLoading,
    error,
  };
};

const getArrayValues = (array) => {
  if (!array || array.length === 0) {
    return [];
  }
  return array.map((item) => item.value);
};
