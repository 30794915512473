/**
 * Created by alex on 10/11/16.
 */
import _ from 'lodash';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { replace, goBack, push } from 'connected-react-router';

import {
  getAppealFromState,
  isAppealReadyToUse,
} from '../../../redux/reducers/AppealStore';
import {
  getAppealById,
  setCurrentStep,
} from '../../../redux/actions/createAppealActions';
import { validateAppealStep } from '../createAppealUtils';
import CreateAppealAPI from '../../../API/CreateAppealAPI';
import { getCurrentAppealData } from '../../../redux/reducers/createAppealStore';

import AppealLetter from './AppealLetter';
import LoadingBlockHelper from '../../Shared/LoadingBlockHelper';
import { ErrorHandling } from '../../Shared/Errors/ErrorHandling';
import { APPEAL_STEPS } from '../../../constants/appConstants';

class AppealLetterContainer extends Component {
  static propTypes = {
    shouldShowPreviousButton: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      templatesDropdownOptions: [],
      isTemplatesDropdownOptionsLoading: false,
      prefillMap: [],
      requestedAppealUpdate: false,
      requestedTemplateDropdownOptions: false,
      appealRequestedFromAppealLetter: false,
    };
  }

  async componentDidMount() {
    await this.props.actions.getAppealById(this.props.appealId);

    validateAppealStep(
      this.props.appealStep,
      APPEAL_STEPS.LETTERS_AND_FORM,
      this.props.appealId,
      this.props.actions.replace,
      this.props.isMedicalRecordsSubmission
    );

    this.props.actions.setCurrentStep(2);
    this.setState({
      requestedAppealUpdate: true,
    });
  }

  componentDidUpdate() {
    if (
      this.props.isLoading === false &&
      this.state.requestedTemplateDropdownOptions === false
    ) {
      this.getTemplateDropdownOptions();

      this.setState({
        requestedTemplateDropdownOptions: true,
      });
    }
  }

  render() {
    return (
      <div>
        {this.renderAppealLetter()}
        {this.renderSpinner()}
      </div>
    );
  }

  renderAppealLetter() {
    if (
      this.props.isLoading === false &&
      this.state.requestedAppealUpdate === true
    ) {
      return (
        <AppealLetter
          {...this.props.appealLetterProperties}
          lastLoadedLetterMeta={
            this.state.appealRequestedFromAppealLetter === true
              ? undefined
              : this.props.lastLoadedLetterMeta
          }
          editorContent={
            this.state.appealRequestedFromAppealLetter === true
              ? undefined
              : this.props.appealLetterProperties.editorContent
          }
          templatesDropdownOptions={this.state.templatesDropdownOptions}
          isTemplatesDropdownOptionsLoading={
            this.state.isTemplatesDropdownOptionsLoading
          }
          companyLogoImage={this.state.companyLogo}
          prefillMap={this.state.prefillMap}
          refetchAppeal={this.refetchCurrentAppeal}
          onEditLetter={this.onEditLetter}
          datacy="AppealLetter"
        />
      );
    }
  }

  renderSpinner() {
    if (this.props.isLoading === true) {
      return <LoadingBlockHelper isLoading={true} />;
    }
  }

  getTemplateDropdownOptions = () => {
    const payerId = this.props.appealLetterProperties.appealData.payer.id;
    const appealId = this.props.appealId;
    const templatesPromise = CreateAppealAPI.getDropdownOptions(
      'appeal_templates',
      {
        payer_id: payerId,
        appeal: appealId,
      }
    );
    const prefillMapPromise = CreateAppealAPI.getDropdownOptions(
      `appeals/${this.props.appealId}/appeal_pdf_fields_map/`
    );
    const clinic = CreateAppealAPI.getDropdownOptions(
      `appeals/${this.props.appealId}/clinic/`
    );

    this.setState({
      isTemplatesDropdownOptionsLoading: true,
    });

    Promise.all([templatesPromise, prefillMapPromise, clinic]).then(
      ([templatesResponse, prefillMap, clinicResponse]) => {
        this.setState({
          templatesDropdownOptions:
            CreateAppealAPI.parseTemplates(templatesResponse),
          prefillMap,
          companyLogo: `"${clinicResponse.data.attributes.logo_base64}"`,
          isTemplatesDropdownOptionsLoading: false,
        });
      }
    );
  };

  refetchCurrentAppeal = () => {
    this.setState(
      {
        appealRequestedFromAppealLetter: true,
      },
      () => {
        this.forceUpdate();
        this.props.actions.getAppealById(this.props.appealId);
      }
    );
  };
}

function mapStateToProps(state, ownProps) {
  const currentAppealId = getCurrentAppealData(state).get('id');
  const appealDetailsWrapper = getAppealFromState(state, currentAppealId);
  const isAppealReady = isAppealReadyToUse(state, currentAppealId);

  if (!isAppealReady) {
    return {
      appealId: currentAppealId,
      isLoading: true,
    };
  }

  let editableLetters = [];
  let letterToEdit, editorContent;

  const query = parse(ownProps.location.search, { ignoreQueryPrefix: true });

  if (query.edit) {
    editableLetters = appealDetailsWrapper.appeal.letters.filter(
      (letter) => letter.id === query.edit
    );
    letterToEdit = {
      id: query.edit,
      name: editableLetters[0].name,
      type: editableLetters[0].letter_type,
    };
    editorContent = removeLogoImgTag(editableLetters[0].html);
  }

  const appeal = appealDetailsWrapper.appeal;

  const shouldShowPreviousButton = appeal.appealRound >= 1;

  return {
    appealId: currentAppealId,
    isLoading: false,
    appealLetterProperties: {
      appealData: appealDetailsWrapper.appeal,
      letterToEdit,
      editorContent,
      shouldShowPreviousButton,
    },
    isMedicalRecordsSubmission: _.get(
      appeal,
      'isMedicalRecordsSubmission',
      false
    ),
    appealStep: appeal.step,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { setCurrentStep, getAppealById, replace, goBack, push },
      dispatch
    ),
    dispatch,
  };
}

function removeLogoImgTag(content) {
  if (_.startsWith(content, '<img')) {
    content = content.replace(/<img[^>]*>/, '');
  }

  return content;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandling(AppealLetterContainer));
