import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { push, replace } from 'connected-react-router';
import {
  setStandaloneSubmissionId,
  setSubmitConfirmPrefillData,
} from 'redux/actions/createSubmitPackageActions';

import * as routes from 'constants/routes';

import { SUBMISSION_MENU_OPTION } from './constants';
import { SUBMISSIONS_IN_PROGRESS } from 'constants/routes';

import { useFetchBatchJobs } from '../hooks';

import * as toast from 'components/Shared/toast';
import { AppealioPopupWithFooter } from 'components/common/popup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';

import { createURLWithParams } from 'API/Config';
import { removeSubmission } from 'API/DeliveryLogsAPI';

const BatchConfirmationPopup = (props) => {
  const { cancelPopup, fetchDeliveryLogs, onClosePopup } = props;

  const { loading: loadingBatchJobs, batchJobIds } = useFetchBatchJobs(
    cancelPopup?.appealId,
    cancelPopup?.isBatchDuplicated
  );

  const { submissionDetails, type, submissionType, submissionId } = cancelPopup;

  const handleClosePopup = () => {
    onClosePopup();
  };

  const handleEditAction = () => {
    if (cancelPopup.submissionType !== 'Standalone') {
      const appealsStepUrl = createURLWithParams(
        routes.CREATE_APPEAL_SUBMIT_CONFIRM,
        {
          appealId: cancelPopup.appealId,
        },
        true
      );
      props.actions.replace(appealsStepUrl);
    } else {
      props.actions.setStandaloneSubmissionId(cancelPopup.submissionId);
      const submissionStepUrl = createURLWithParams(
        routes.CREATE_APPEAL_SUBMIT_CONFIRM,
        {
          submissionId: cancelPopup.submissionId,
        },
        true
      );
      props.actions.replace(submissionStepUrl);
    }
  };

  const handleContinueButtonClick = async () => {
    try {
      props.actions.setSubmitConfirmPrefillData(
        cancelPopup?.submissionDetails || {}
      );
      await removeSubmission(submissionId, cancelPopup.type);
      handleClosePopup();
      toast.success({
        title: 'Success',
        message: 'Your submission has been successfully canceled.',
      });

      if (cancelPopup.type === SUBMISSION_MENU_OPTION.CANCEL) {
        fetchDeliveryLogs();
      }

      if (cancelPopup.type === SUBMISSION_MENU_OPTION.EDIT) {
        handleEditAction();
      }
    } catch (error) {
      toast.error({
        title: '',
        message: 'Oops! Something went wrong. Please try again later.',
      });
      handleClosePopup();
    }
  };

  const onContinueClick = () => {
    switch (type) {
      case SUBMISSION_MENU_OPTION.EDIT:
        handleContinueButtonClick();
        break;
      case SUBMISSION_MENU_OPTION.CANCEL:
        handleContinueButtonClick();
        break;

      default:
        break;
    }
  };

  const redirectToSubmissions = (batchIds) => {
    const appealsTableUrl = createURLWithParams(
      SUBMISSIONS_IN_PROGRESS,
      {
        set_filters: true,
        batch_ids: batchIds,
      },
      true
    );
    props.actions.push(appealsTableUrl);
  };

  const renderBatchMessage = (batchJobIds, isLoading) => {
    const MAX_DISPLAY_IDS = 1;

    if (isLoading) {
      return (
        <div className="position-relative">
          <LoadingIndicator showing />
        </div>
      );
    }

    if (!batchJobIds?.length) return null;

    const formatBatchIds = () => {
      const displayIds = batchJobIds
        .slice(0, MAX_DISPLAY_IDS)
        .map((id) => `#${id}`)
        .join(', ');
      const remainingCount = batchJobIds.length - MAX_DISPLAY_IDS;
      return remainingCount > 0
        ? `${displayIds} + ${remainingCount}`
        : displayIds;
    };

    const tooltipContent = `Batch: ${batchJobIds
      .map((id) => `#${id}`)
      .join(', ')}`;

    return (
      <div className="mb-16">
        <span
          data-tip={tooltipContent}
          data-for="batch-job-tooltip"
          className="batch-ids-tooltip"
        ></span>{' '}
        <div className="fw-bold">
          Submission{' '}
          {submissionType === 'Appeal'
            ? submissionDetails?.claimNumber
            : submissionDetails?.submitterClaimId}{' '}
          is tied to Submissions Batch: {formatBatchIds()}. Are you sure you
          want to continue with the action?
        </div>
        <ReactTooltip
          effect="solid"
          place="top"
          id="batch-job-tooltip"
          className="batch-tooltip"
        />
      </div>
    );
  };

  return (
    <AppealioPopupWithFooter
      className="appealio-popup--v3 appealio-popup--v3-confirmation-popup batch-confirmation-popup"
      title="Are you Sure?"
      onClosePressed={handleClosePopup}
      isFooterOutside={false}
    >
      <div>
        {renderBatchMessage(batchJobIds, loadingBatchJobs)}
        <div className="appealio-popup--v3__footer appealio-popup--v3__footer--inside">
          <div className="d-flex justify-content--space-between">
            <button
              onClick={() => redirectToSubmissions(batchJobIds)}
              className="ml-0 ap-button--batch--in-progress"
            >
              View In progress Batched{' '}
              {submissionType === 'Appeal' ? 'Appeal(s)' : 'Record(s)'}
            </button>
            <button
              className="ml-0 ap-button ap-button--secondary border-none"
              onClick={onContinueClick}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </AppealioPopupWithFooter>
  );
};

BatchConfirmationPopup.propTypes = {
  submissionId: PropTypes.number,
  cancelPopup: PropTypes.object,
  setCancelPopup: PropTypes.func,
  fetchDeliveryLogs: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { replace, push, setSubmitConfirmPrefillData, setStandaloneSubmissionId },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchConfirmationPopup);
