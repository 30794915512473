import { get } from 'lodash';
import classnames from 'classnames';
import { IoIosArrowDown } from 'react-icons/io';
import { reduxForm } from 'redux-form/immutable';
import React, { useState, useEffect } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import TextHighlighter from 'components/common/textHighlighter';

import './FeeScheduleTable.css';

import * as toast from 'components/Shared/toast';
import { AlertDialog } from 'components/common/popup';
import LoadingIndicator from 'components/Shared/LoadingIndicator';
import AddOrEditFeeScheduleForm from './AddOrEditFeeScheduleForm';
import Button, { BUTTON_TYPE } from 'components/common/button/Button';

import { useFetchFeeSchedules } from '../hook';

import { SEARCH_QUERY_CHARACTERS_OFFSET } from '../constants';

import { handleError } from 'helpers/errorHandler';
import { DATE_FORMAT_YYYY_MM_DD, getFormattedDate } from 'helpers/dateFormats';

import {
  addFeeSchedule,
  updateFeeSchedule,
  deleteFeeSchedule,
} from 'API/AccountSettingsAPI';

const FeeScheduleTable = (props) => {
  const {
    cptCode,
    cptCodes,
    payerId,
    practiceId,
    isCptCodesLoading,
    showAddFeeScheduleForm = false,
    closeAddFeeScheduleForm,
    editingFeeScheduleId,
    setEditingFeeScheduleId,
    fetchPayersWithCptCount,
  } = props;

  const [contractedRateDeletePopup, setContractedRateDeletePopup] = useState({
    isOpen: false,
    id: null,
  });
  const {
    feeSchedules,
    loading: isFetchingFeeSchedules,
    fetch: fetchFeeSchedules,
  } = useFetchFeeSchedules(practiceId, payerId, cptCode);

  const [cptCodesOptions, setCptCodesOptions] = useState(cptCodes);

  const handleEditClick = (id) => {
    setEditingFeeScheduleId(id);
  };

  const handleCancelEdit = () => {
    setEditingFeeScheduleId(null);
  };
  const showToastError = (message) => {
    toast.error({
      title: '',
      message,
    });
  };

  const validate = (formValues) => {
    if (!formValues?.cptCode) {
      return showToastError('Procedure Code is required');
    }
    if (!formValues?.allowedAmount || formValues?.allowedAmount <= 0.01) {
      return showToastError('Allowed Amount must be greater than $0.01');
    }

    if (!formValues?.expirationDate) {
      return showToastError(
        'Expiration Date is required. Expiration date must be in the format of MM/DD/YYYY'
      );
    }
    return true;
  };

  const onAddFeeSchedule = async (values) => {
    if (feeSchedules?.length >= 25) {
      return showToastError('You can only add 25 contracted rates per payer');
    }
    const formValues = values.toJS();
    if (!validate(formValues)) return;

    const feeSchedule = {
      ...formValues,
      payerId,
      practiceId,
      cptCode: formValues?.cptCode?.value,
      expirationDate: getFormattedDate(
        formValues?.expirationDate,
        DATE_FORMAT_YYYY_MM_DD
      ),
    };

    try {
      await addFeeSchedule(feeSchedule);
      toast.success({
        title: 'Success',
        message: 'Contracted Rate added successfully.',
      });
      closeAddFeeScheduleForm();
      fetchFeeSchedules();
      fetchPayersWithCptCount();
    } catch (error) {
      const errorResponse = error.response;

      if (errorResponse.status === 400) {
        const response = await error.response.json();

        const nonFieldErrors = get(response, 'user.non_field_errors', []);
        nonFieldErrors.forEach((error) => {
          toast.error({
            message: error,
            title: '',
          });
        });
        return;
      }

      return handleError(error);
    }
  };

  const onUpdateFeeSchedule = async (values) => {
    const formValues = values.toJS();
    if (!validate(formValues)) return;

    const feeSchedule = {
      ...formValues,
      payerId,
      practiceId,
      cptCode: formValues?.cptCode?.value || formValues?.cptCode,
      expirationDate: getFormattedDate(
        formValues?.expirationDate,
        DATE_FORMAT_YYYY_MM_DD
      ),
    };

    try {
      await updateFeeSchedule(editingFeeScheduleId, feeSchedule);
      toast.success({
        title: 'Success',
        message: 'Contracted Rate updated successfully.',
      });
      handleCancelEdit();
      fetchFeeSchedules();
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteFeeSchedule = async (feeScheduleId) => {
    try {
      await deleteFeeSchedule(feeScheduleId);
      toast.success({
        title: 'Success',
        message: 'Contracted Rate deleted successfully.',
      });
      setContractedRateDeletePopup({
        isOpen: false,
        id: null,
      });
      fetchFeeSchedules();
      fetchPayersWithCptCount();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const filterCptCodes = () => {
      const cptCodesFromFeeSchedule = feeSchedules?.map(
        (feeSchedule) => feeSchedule.cptCode
      );
      const filteredCptCodes = cptCodes?.filter(
        (cptCode) => !cptCodesFromFeeSchedule.includes(cptCode.value)
      );
      setCptCodesOptions(filteredCptCodes);
    };

    filterCptCodes();
  }, [feeSchedules, cptCodes, editingFeeScheduleId]);

  return (
    <>
      <form className="fee-schedule-table-responsive">
        <table className="fee-schedule-table">
          <thead>
            <tr className="fee-schedule-table__tr">
              <th
                scope="col"
                className={classnames('fee-schedule-table__th', {
                  'width-170': showAddFeeScheduleForm || editingFeeScheduleId,
                })}
              >
                Procedure Code
              </th>
              <th scope="col" className="fee-schedule-table__th">
                <div className="d-flex align-items-center fw-bold">
                  <span className="mr-4"> Allowed Amount</span>
                  <span className="mt-4">
                    <IoIosArrowDown size="16" />
                  </span>
                </div>
              </th>
              <th scope="col" className="fee-schedule-table__th">
                Expiration Date
              </th>
              <th scope="col" className="fee-schedule-table__th">
                Created On
              </th>
              <th scope="col" className="fee-schedule-table__th"></th>
            </tr>
          </thead>
          <tbody>
            {showAddFeeScheduleForm && (
              <AddOrEditFeeScheduleForm
                cptCodes={cptCodesOptions}
                isCptCodesLoading={isCptCodesLoading}
                closeAddFeeScheduleForm={closeAddFeeScheduleForm}
                onSubmit={onAddFeeSchedule}
              />
            )}
            {feeSchedules?.length === 0 && !isFetchingFeeSchedules && (
              <tr className="fee-schedule-table__tr">
                <td colSpan="100" className="fee-schedule-table__td">
                  <div className="d-flex justify-content-center">
                    No fee schedules found. Please add a new fee schedule.
                  </div>
                </td>
              </tr>
            )}
            {isFetchingFeeSchedules ? (
              <tr className="fee-schedule-table__tr">
                <td colSpan="100" className="fee-schedule-table__td">
                  <div className="d-flex justify-content-center position-relative">
                    <LoadingIndicator showing />
                  </div>
                </td>
              </tr>
            ) : (
              feeSchedules?.map((feeSchedule) => {
                const initialValues = {
                  cptCode: feeSchedule.cptCode,
                  allowedAmount: feeSchedule.allowedAmount,
                  expirationDate: feeSchedule.expirationDate,
                };
                return (
                  <React.Fragment key={feeSchedule.id}>
                    {editingFeeScheduleId === feeSchedule.id ? (
                      <AddOrEditFeeScheduleForm
                        cptCodes={[
                          ...cptCodesOptions,
                          {
                            value: feeSchedule.cptCode,
                            label: feeSchedule.cptCode,
                          },
                        ]}
                        isCptCodesLoading={isCptCodesLoading}
                        closeAddFeeScheduleForm={handleCancelEdit}
                        initialValues={initialValues}
                        onSubmit={onUpdateFeeSchedule}
                      />
                    ) : (
                      <tr className="fee-schedule-table__tr">
                        <td className="fee-schedule-table__td">
                          {feeSchedule.cptCode &&
                          cptCode?.length >= SEARCH_QUERY_CHARACTERS_OFFSET ? (
                            <TextHighlighter
                              text={feeSchedule.cptCode}
                              query={cptCode}
                            />
                          ) : (
                            <span>{feeSchedule.cptCode || 'N/A'}</span>
                          )}
                        </td>
                        <td className="fee-schedule-table__td">
                          {feeSchedule.allowedAmount || 'N/A'}
                        </td>
                        <td className="fee-schedule-table__td">
                          {feeSchedule?.expirationDate
                            ? getFormattedDate(feeSchedule.expirationDate)
                            : 'N/A'}
                        </td>
                        <td className="fee-schedule-table__td">
                          {feeSchedule?.createdAt
                            ? getFormattedDate(feeSchedule.createdAt)
                            : 'N/A'}
                        </td>
                        <td className="fee-schedule-table__td">
                          <div className="d-flex justify-space--between align-items-center">
                            <BsPencil
                              className="mr-16 cursor-pointer outline-none"
                              data-tip="Edit"
                              onClick={() => {
                                if (showAddFeeScheduleForm) {
                                  return toast.error({
                                    title: 'Error',
                                    message:
                                      'Please save or cancel the current contracted fee form before adding a new fee contracted rate.',
                                  });
                                }
                                handleEditClick(feeSchedule.id);
                              }}
                            />
                            <BsTrash
                              className="cursor-pointer outline-none"
                              data-tip="Delete"
                              onClick={() => {
                                if (showAddFeeScheduleForm) {
                                  return toast.error({
                                    title: 'Error',
                                    message:
                                      'Please save or cancel the current contracted fee form before adding a new fee contracted rate.',
                                  });
                                }
                                setContractedRateDeletePopup({
                                  isOpen: true,
                                  id: feeSchedule.id,
                                });
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr className="spacer fee-schedule-table__tr">
                      <td colSpan="100" className="fee-schedule-table__td"></td>
                    </tr>
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
      </form>
      {contractedRateDeletePopup.isOpen && (
        <AlertDialog
          onClosePressed={() => {
            setContractedRateDeletePopup({
              isOpen: false,
              feeScheduleId: null,
            });
          }}
        >
          Are you sure you want to delete this Contracted Rate ?
          <hr className="card-horizontal-line" />
          <div className="import-rule-practice-agent-permission__buttons-wrapper">
            <button
              className="ap-button ap-button--secondary width-80 justify-content-center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDeleteFeeSchedule(contractedRateDeletePopup?.id);
              }}
            >
              Continue
            </button>
            <Button
              title="Cancel"
              type={BUTTON_TYPE.LIGHT}
              className="width-80 justify-content-center fw-normal ml-8"
              onClick={() => {
                setContractedRateDeletePopup({
                  isOpen: false,
                  feeScheduleId: null,
                });
              }}
              datacy="cancel-Button"
            />
          </div>
        </AlertDialog>
      )}
    </>
  );
};

export default reduxForm()(FeeScheduleTable);
