import download from 'downloadjs';
import {
  handleErrors,
  handleJSONParse,
  createGetRequest,
  APIConfig,
  createURLWithParams,
  createRequest as createPostRequest,
  INTEGRATION_API_BASE_URL,
  BASE_API_URL,
  createFormDataRequest,
  createDeleteRequest,
} from './Config';

import { interpolate } from 'helpers/string';
import { camelizeKeys, snakeCaseKeys } from 'helpers/object';

import * as endpoints from 'constants/endpoints';
import { SUBMISSION_MENU_OPTION } from 'components/Submissions/DeliveryTracking/DeliveryLogsTable/constants';

export const fetchDeliveryLogs = async (queryParams = {}) => {
  const request = createGetRequest(endpoints.DELIVERY_TRACKING, queryParams);
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

/**
 * Fetch Notes counts
 * @param {Object} data
 * @returns {Promise}
 */
export const fetchNotesCount = (payload) => {
  const request = createPostRequest(
    endpoints.NOTES_COUNT,
    snakeCaseKeys(payload),
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'POST',
    }
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const fetchSopAndRuleInfo = (payload) => {
  const request = createPostRequest(
    endpoints.SOP_AND_RULE_INFO,
    snakeCaseKeys(payload),
    {
      baseUrl: INTEGRATION_API_BASE_URL,
      method: 'POST',
    }
  );
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const createSubmittedPackageLink = (url) => {
  const finalLink = createURLWithParams(url, APIConfig.processParams());
  return finalLink;
};

export const downloadPreviewPackage = async (url) => {
  const previewPackage = await fetch(url, APIConfig.processParams())
    .then(handleErrors)
    .then((response) => response.blob());

  return previewPackage;
};

export const downloadProofOfSubmission = async (logId) => {
  const url = interpolate(endpoints.DOWNLOAD_PROOF_OF_SUBMISSION, {
    logId,
  });

  const request = createGetRequest(url);
  return await fetch(request)
    .then(handleErrors)
    .then(function (response) {
      return response.blob();
    });
};

export const downloadDeliveryReport = async (
  params,
  filename = 'download.csv',
  mimeType = 'text/csv'
) => {
  const request = createGetRequest(endpoints.EXPORT_DELIVERY_TRACKING, params);

  return await fetch(request)
    .then(handleErrors)
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, filename, mimeType);
    });
};

export const createIdenticalAppealsWithExistingInfo = async (payload) => {
  const isFormData = payload instanceof FormData;
  const request = isFormData
    ? createFormDataRequest(endpoints.CREATE_SUBMIT_APPEAL, payload)
    : createPostRequest(endpoints.CREATE_SUBMIT_APPEAL, payload, {
        baseUrl: BASE_API_URL,
        method: 'POST',
      });
  return fetch(request)
    .then(handleErrors)
    .then(handleJSONParse)
    .then(camelizeKeys);
};

export const removeSubmission = async (submissionId, popType) => {
  const request = createDeleteRequest(
    `${endpoints.DELIVERY_TRACKING}${submissionId}`,
    {
      reset: popType === SUBMISSION_MENU_OPTION.CANCEL ? 0 : 1,
    }
  );
  return await fetch(request).then(handleErrors);
};
